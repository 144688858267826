<template>
  <div class="enlaces">
    <!-- Submenu para Collares -->
    <div class="submenu-container" @click="toggleSubmenu('collares')">
      <span class="enlace">Collares|</span>
      <div v-if="activeSubmenu === 'collares'" class="submenu">
        <router-link class="submenu-enlace" to="/collares/collares_y_dijes">Collares y dijes en Acero Inoxidable</router-link>
        <router-link class="submenu-enlace" to="/collares/collares_en_parejas">Collares en Parejas Acero Inoxidable</router-link>
        <router-link class="submenu-enlace" to="/collares/collares_y_perlas_naturales">Collares en Acero Inoxidable y Perlas Naturales</router-link>
        <router-link class="submenu-enlace" to="/collares/collares_y_dijes_cristales">Collares en Acero Inoxidable y dijes de cristales</router-link>
        <router-link class="submenu-enlace" to="/collares/collares_y_cuarzos">Collares en Acero Inoxidable y Cuarzos</router-link>
        <router-link class="submenu-enlace" to="/collares/collares_y_dijes_enchapados">Collares en Acero  Inoxidable y dijes enchapados</router-link>
      </div>
    </div>

    <!-- Submenu para Anillos -->
    <div class="submenu-container" @click="toggleSubmenu('anillos')">
      <span class="enlace">Anillos|</span>
      <div v-if="activeSubmenu === 'anillos'" class="submenu">
        <router-link class="submenu-enlace" to="/anillos/oro">Anillos enchapados en oro</router-link>
        <router-link class="submenu-enlace" to="/anillos/acero">Anillos en acero inoxidable</router-link>
      </div>
    </div>

    <!-- Submenu para Pulseras -->
    <div class="submenu-container" @click="toggleSubmenu('pulseras')">
      <span class="enlace">Pulseras|</span>
      <div v-if="activeSubmenu === 'pulseras'" class="submenu">
        <router-link class="submenu-enlace" to="/pulseras/acero">Pulseras en Acero inoxidable</router-link>
        <router-link class="submenu-enlace" to="/pulseras/platino">Pulseras en Platino</router-link>
        <router-link class="submenu-enlace" to="/pulseras/hilo">Pulseras en Hilo</router-link>
      </div>
    </div>

    <router-link class="enlace" to="/aretes">Aretes|</router-link>

    <router-link class="enlace" to="/conjuntos">Conjuntos|</router-link>

    <!-- Submenu para charms -->
    <div class="submenu-container" @click="toggleSubmenu('charms')">
      <span class="enlace">Charms|</span>
      <div v-if="activeSubmenu === 'charms'" class="submenu">
        <router-link class="submenu-enlace" to="/charms/charms_pulseras_europeo">Charms Acero Inoxidable para<br>pulseras estilo Europeo</router-link>
        <router-link class="submenu-enlace" to="/charms/charms_pulserasSerpiente">Charms Acero Inoxidable para<br>Pulseras córdon Serpiente</router-link>
        <router-link class="submenu-enlace" to="/charms/charms_perlas_colores">Charms de Perlas de Colores</router-link>
      </div>
    </div>

    <!-- Submenu para accesorios -->
    <div class="submenu-container" @click="toggleSubmenu('accesorios')">
      <span class="enlace">Accesorios|</span>
      <div v-if="activeSubmenu === 'accesorios'" class="submenu">
        <router-link class="submenu-enlace" to="/accesorios/tarjetas_dedicatorias">Tarjetas dedicatorias</router-link>
        <router-link class="submenu-enlace" to="/accesorios/llaveros_resina">Llaveros de resina</router-link>
        <router-link class="submenu-enlace" to="/accesorios/cajitas_de_formas">Cajitas de formas</router-link>
        <router-link class="submenu-enlace" to="/accesorios/cajitas_sencillas">Cajitas sencillas</router-link>
        <router-link class="submenu-enlace" to="/accesorios/joyeros">Joyeros</router-link>
      </div>
    </div>

    <!-- <router-link class="enlace" to="/cadenas">Cadenas|</router-link> -->
    <router-link class="enlace" to="/carrito">Carrito ({{ itemCount }})</router-link>

    <!-- Icono flotante carrito de compras -->
    <router-link class="floating-cart" to="/carrito">
    ({{ itemCount }})
    </router-link>
  </div>

  <Footer/> 
</template>

<script>
export default {
  data() {
    return {
      activeSubmenu: null,  // Almacena qué submenú está abierto
    };
  },
  methods: {
    toggleSubmenu(menu) {
      // Cambia entre submenús, cerrando el que esté abierto si se hace clic en otro
      this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
    }
  },
  computed: {
    itemCount() {
      return this.$store.state.carrito.length;
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";

.enlaces {
  margin-top: 17rem;
  display: flex;
  justify-content: space-between;
  padding: 0 3%;
  flex-wrap: wrap;

  .enlace {
    font-size: 1.8rem;
    color: $violeta;
    margin: 0 .5rem;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 2rem;
  }

  .submenu-container {
    position: relative;
  }

  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 0.5rem;
    z-index: 1;
    white-space: nowrap;

    .submenu-enlace {
      display: block;
      font-size: 1.8rem;
      color: $violeta;
      margin: 1.5rem .5rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .submenu-container > .submenu {
    display: block;
  }
}

/* Estilo para el icono flotante */
.floating-cart {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: $violeta;
  color: white;
  padding: 1.5rem; // Ajusta el padding para asegurar que sea perfectamente circular
  border-radius: 50%;
  font-size: 2.4rem;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  transition: background-color 0.3s;
  text-decoration: none; // Elimina el subrayado

  &:hover {
    background-color: darken($violeta, 10%);
  }
}

</style>

