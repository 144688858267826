<template>
    <section class="home" id="home">
      <div class="swiper home-slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide slide">
            <div class="content">
              <h3>ACCESORIOS HECHOS A MANO</h3>
            </div>
            <router-link to="/aretes"><h3 class="btn">Comprar ahora</h3></router-link>
          </div>
          <div class="swiper-slide slide">
            <div class="content">
              <h3>DISEÑOS EXCLUSIVOS PARA TI</h3>
            </div>
            <router-link to="/aretes"><h3 class="btn">Comprar ahora</h3></router-link>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
  </template>
  
  <script>
  import Swiper from 'swiper/bundle';
  
  export default {
    mounted() {
      // Inicializar Swiper solo una vez
      this.initSwiper();
    },
    methods: {
      initSwiper() {
        const swiper = new Swiper('.home-slider', {
          spaceBetween: 20,
          effect: 'fade',
          grabCursor: true,
          loop: true,
          autoplay: {
            delay: 3500,
            disableOnInteraction: false,
          },
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });
      },
    },
  };
  </script>
  
  <style lang="scss">
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";

  .home {
    padding: 0 6%;
    margin-top: 9rem;
    background: #f8f2fc;
  
    .slide {
      background-image: url('../assets/img/slider.png');
      min-height: 100vh;
      background-size: cover !important;
      background-position: 0 63% !important;
      padding: 0;
      display: flex;
      align-items: center;
  
      .content {
        width: 50%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
  
        h3 {
          font-size: 3.5rem;
          color: #ffffff;
          display: block;
          font-weight: 600;
          letter-spacing: 1rem;
        }
  
        p {
          color: #ffffff;
          font-size: 2.5rem;
          margin-top: 5rem;
        }
      }
    }
  }
  
  .btn {
    padding: 2rem 4rem;
    background: rgba(115, 38, 107, 0.5);
    color: #ffffff;
    cursor: pointer;
    font-size: 1.7rem;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ffffff;
    font-weight: 500;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    text-decoration: none;
  
    &:hover {
      background: rgba(115, 38, 107, 0.9);
      border: 1px solid #ffffff;
    }
  }
  
  span.swiper-pagination-bullet {
    height: 2rem;
    width: 2rem;
    background: rgb(108, 108, 108);
    border-radius: 50%;
    border: 1px solid #ffffff;
  
    &.swiper-pagination-bullet-active {
      background: $violeta; /* Se espera que $violeta esté definido en variables.scss */
    }
  }


  @media (max-width:700px){
    .home{
        padding: 0;
    }

    .home .swiper-wrapper .slide .content{
        width: 90%;
    }
}

@media (max-width:450px){
    .home .slide{
        min-height: 67vh;
        background-position: 55% 90% !important;
    }

    .home .swiper-wrapper .slide .content{
        h3{
          font-size: 2.8rem;
        } 
    }
}
  </style>