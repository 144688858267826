<template>
    <navCategoria/>
    <Enlaces/>
    <router-view></router-view>
    <!-- <div class="enlaces">
        <router-link class="enlace" to="/aretes">Aretes</router-link>
        <router-link class="enlace" to="/pulseras">Pulseras</router-link>
        <router-link class="enlace" to="/cadenas">Cadenas</router-link>
        <router-link class="enlace" to="/carrito">Carrito ({{ itemCount }})</router-link>
    </div> -->

</template>

<script>
import NavCategoria from '../components/navCategoria.vue'
import Enlaces from '../components/enlaces.vue'

export default {
  name: 'App',
  components: {
    NavCategoria,
    Enlaces,
  },
}
</script>

<style lang="scss">

//    .enlaces{
//        margin-top:20rem; 

//        .enlace{
//         font-size: 1.8rem;
//         color: green;
//         margin: 0 .5rem;
//        }
//    }

</style>