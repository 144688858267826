<template>
    <header class="header">
        <div class="nav-superior">
            <p>Próximamente: Embajadores de la marca</p>
        </div>
        <div class="header-center">
            <div class="header-center-items">
                <figure>
                    <img src="@/assets/img/logo.png" alt="Logo">
                </figure>

                <div id="menu-btn" class="fas fa-bars"></div>

                <nav class="navbar">
                    <router-link to="/home">Inicio</router-link>
                    <router-link to="/aretes">Catálogo</router-link>
                    <a href="#sobre-nosotros">Sobre Nosotros</a>
                    <a href="#contactanos">Contáctanos</a>
                </nav>

                <!-- <div class="logos-redes-sociales">
                    <a href="https://www.facebook.com/GlamourbyRamos17/" class="bx bxl-facebook" target="_blank"></a>
                    <a href="https://www.instagram.com/glamourbyramos/" class="bx bxl-instagram" target="_blank"></a>
                    <a href="https://api.whatsapp.com/send/?phone=%2B50587617146" class="bx bxl-whatsapp" target="_blank"></a>
                    <a href="https://pin.it/5twLola" class="bx bxl-pinterest-alt" target="_blank"></a>
                </div> -->
            </div>
        </div>
    </header>
    <!--final header-->
</template>

<script>
export default {
  mounted() {
    let menu = document.querySelector('#menu-btn');
    let navbar = document.querySelector('.header .navbar');

    menu.onclick = () => {
        menu.classList.toggle('fa-times'); /* activa la x */
        navbar.classList.toggle('active');
    }

    /* desaparece el menú responsivo al darle click en un link */
    window.onscroll = () => {
        menu.classList.remove('fa-times');
        navbar.classList.remove('active');
    }
  }
}
</script>

<style lang="scss">
//css
@import "@/assets/styles/normalize.css";
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/fonts.scss";
//iconos
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');
//menu hamburguesa
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

//header
.header{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4000;
    border-bottom: 1px solid rgba(5, 83, 85, 30%);

    .nav-superior{
        background: #000;
        text-align: center;

        p{
            color: #ffffff;
            padding: 1rem 0;
            letter-spacing: 0.2rem;
            font-size: 1.2rem;
        }
    }

    .header-center{
        background: #ffffff;
        
        .header-center-items{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0rem 6%;

            figure img{
                height: 11rem;
                width: 22rem;
                object-fit: cover;
            }

            // .logos-redes-sociales .bx{
            //     font-size: 2.5rem;
            //     margin: 0 0.2rem 0 0.2rem;
            //     color: $violeta;
            //     text-decoration: none;

            //     &:hover{
            //         transform: scale(1.1);
            //     }
            // }

            .navbar{
                a{
                    display: inline-block;
                    color: $violeta;
                    font-size: 1.8rem;
                    font-weight: 400;
                    margin-left: 2rem;
                    text-decoration: none;

                    &:hover{
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}

#menu-btn{
    font-size: 3rem;
    cursor: pointer;
    display: none;
}

@media (max-width:768px){
    #menu-btn{
        font-size: 2.5rem;
        display: inline-block;
        color: $violeta;
        /*rotar la X */
        &.fa-times{
            transform: rotate(180deg);
        }
    }

    .header{
        .header-center{
            .header-center-items{
                flex-direction: row;
                padding: 0.5rem 3%;

                .navbar{
                    position: absolute;
                    top:100%; left:0; right:0;
                    background: #fff;
                    /* desaparece menu responsivo */
                    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                    transition: clip-path 0.3s ease-out;

                    /* aparece menu responsivo */
                    &.active{
                        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    }

                    a{
                        display: block;
                        font-size: 2rem;
                        color: red;
                        padding: 2.5rem 3%;
                        border-bottom: 1px solid $violeta;

                        &:hover{
                            color: $dorado ;
                        }
                    }
                }

                // .logos-redes-sociales .bx{
                //     font-size: 2.5rem;
                //     margin: 0 0.3rem;
                // }
            }
        }
    }
}
</style>
