<template>
    <navCategoria/>
    <Enlaces/>
  
    <div class="productos-container">
      <!-- <h1>{{ titulo }}</h1> -->
      <div class="contenedor-titulos scroll-up">
              <img src="@/assets/img/fondo-titulos.png" alt="">
              <div class="texto"><h2>{{titulo}}</h2></div>
      </div>
      <div class="productos">
        <div class="producto" v-for="producto in productos" :key="producto.id">
          <div class="img-container">
            <img :src="producto.imagen" :alt="producto.nombre">
            <div class="icon-eye" @click="abrirModalInfo(producto)">
              <i class="bx bx-show"></i>
            </div>
          </div>
          <p class="ref">{{ producto.referencia }}</p>
          <p class="nombre-producto">{{ producto.nombre }}</p>
          <p class="precio">{{ producto.precio }}</p>
          <div class="cantidad">
            <button @click="decrementarCantidad(producto)">-</button>
            <span>{{ producto.cantidad }}</span>
            <button @click="incrementarCantidad(producto)">+</button>
          </div>
          <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
        </div>
      </div>
      
      <!-- Modal para agregar al carrito -->
      <div v-if="mostrarModalCarrito" class="modal">
        <div class="modal-content">
          <!-- <span class="close" @click="cerrarModalCarrito">&times;</span> -->
          <p>Producto agregado al carrito</p>
        </div>
      </div>
      
      <!-- Modal para información del producto -->
      <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
        <div class="modal-content">
          <span class="close" @click="cerrarModalInfo">&times;</span>
          <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
          <p>Descripción</p>
          <li>{{ productoSeleccionado.descripcion }}</li>
        </div>
      </div>
    </div>
    <Footer/> 
  </template>
  
  
  
  
  <script>
  import NavCategoria from '@/components/navCategoria.vue'
  import Enlaces from '@/components/enlaces.vue'
  import Footer from '@/components/footer.vue'
  
  export default {
    components: {
      NavCategoria,
      Enlaces,
      Footer,
    },
    data() {
      return {
        titulo: "Anillos enchapado en oro",
        productos: [
          {
            id: 1,
            referencia: "ref: GBR-RJEW-B028-23G",
            nombre: "Anillo abierto",
            precio: "C$285.00",
            imagen: require('@/assets/img/anillos/anillos_oro/1-GBR-RJEW-B028-23G. Anillo abierto con diamantes y circonita cúbica transparente para mujer, en oro real de 18 quilates, diámetro interior 16 mm. C$285.00.png'),
            descripcion: "Anillo abierto con diamantes y circonita cúbica transparente para mujer, en oro real de 18 quilates, diámetro interior 16 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 2,
            referencia: "ref: GBR-RJEW-C056-01P",
            nombre: "Anillo abierto",
            precio: "C$235.00",
            imagen: require('@/assets/img/anillos/anillos_oro/2-GBR-RJEW-C056-01P. Anillo abierto con corona de circonita cúbica transparente, enchapado, platino, diámetro interior 17 mm. C$235.00.png'),
            descripcion: "Anillo abierto con corona de circonita cúbica transparente, enchapado, platino, diámetro interior 17 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 3,
            referencia: "ref: GBR-RJEW-C056-06P",
            nombre: "Anillo plateado",
            precio: "C$235.00",
            imagen: require('@/assets/img/anillos/anillos_oro/3-GBR-RJEW-C056-06P. Anillo plateado abierto con lazo de circonita cúbica transparente, enchapado, platino, talla estadounidense 7.5. C$235.00.png'),
            descripcion: "Anillo plateado abierto con lazo de circonita cúbica transparente, enchapado, platino, talla estadounidense 7.5.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 4,
            referencia: "ref: GBR-RJEW-E064-01P-01",
            nombre: "Anillo ajustable",
            precio: "C$315.00",
            imagen: require('@/assets/img/anillos/anillos_oro/4-GBR-RJEW-E064-01P-01. Anillo ajustable de circonita cúbica rosa, enchapado, platino, talla estadounidense 6.5-7.5. C$315.00.png'),
            descripcion: "Anillo ajustable de circonita cúbica rosa, enchapado, platino, talla estadounidense 6.5-7.5.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 5,
            referencia: "ref: GBR-RJEW-E066-08G",
            nombre: "Anillo de acero",
            precio: "C$215.00",
            imagen: require('@/assets/img/anillos/anillos_oro/5GBR-RJEW-E066-08G. Anillo de acero inoxidable 304 con baño y corona abierta para mujer, chapado en oro real de 18 quilates, talla estadounidense 6.5-7. C$215.00.png'),
            descripcion: "Anillo de acero inoxidable 304 con baño y corona abierta para mujer, chapado en oro real de 18 quilates, talla estadounidense 6.5-7.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 6,
            referencia: "ref: GBR-RJEW-E072-04G",
            nombre: "Anillo abierto",
            precio: "C$215.00",
            imagen: require('@/assets/img/anillos/anillos_oro/6-GBR-RJEW-E072-04G. Anillo abierto ovalado con circonita cúbica transparente, enchapado, dorado, diámetro interior 16 mm. C$215.00.png'),
            descripcion: "Anillo abierto ovalado con circonita cúbica transparente, enchapado, dorado, diámetro interior 16 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 7,
            referencia: "ref: GBR-RJEW-E072-04P",
            nombre: "Anillo plateado",
            precio: "C$215.00",
            imagen: require('@/assets/img/anillos/anillos_oro/7-GBR-RJEW-E072-04P. Anillo plateado abierto ovalado con circonita cubica transparente, enchapado, platino, diametro interior 16 mm. C$215.00.png'),
            descripcion: "Anillo plateado abierto ovalado con circonita cubica transparente, enchapado, platino, diametro interior 16 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 8,
            referencia: "ref: GBR-RJEW-E072-22G",
            nombre: "Anillo abierto",
            precio: "C$225.00",
            imagen: require('@/assets/img/anillos/anillos_oro/8-GBR-RJEW-E072-22G. Anillo abierto con formas de corazón de circonita cúbica transparente, enchapado dorado, diámetro interior 18 mm. C$225.00.png'),
            descripcion: "Anillo abierto con formas de corazón de circonita cúbica transparente, enchapado dorado, diámetro interior 18 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 9,
            referencia: "ref: GBR-RJEW-E264-02G",
            nombre: "Anillo ajustable",
            precio: "C$295.00",
            imagen: require('@/assets/img/anillos/anillos_oro/9-GBR-RJEW-E264-02G. Anillo ajustable de circonita cúbica, joyería bañada en oro real de 18 quilates para muje diámetro interior 17-22 mm. C$295.00.png'),
            descripcion: "Anillo ajustable de circonita cúbica, joyería bañada en oro real de 18 quilates para muje diámetro interior 17-22 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 10,
            referencia: "ref: GBR-RJEW-H127-03G",
            nombre: "Anillo abierto",
            precio: "C$255.00",
            imagen: require('@/assets/img/anillos/anillos_oro/10-GBR-RJEW-H127-03G. Anillo abierto con forma de estrella de circonita cúbica transparente,enchapado, dorado, talla estadounidense 6.5-7. C$255.00.png'),
            descripcion: "Anillo abierto con forma de estrella de circonita cúbica transparente,enchapado, dorado, talla estadounidense 6.5-7.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 11,
            referencia: "ref: GBR-RJEW-H127-03P",
            nombre: "Anillo abierto",
            precio: "C$255.00",
            imagen: require('@/assets/img/anillos/anillos_oro/11-GBR-RJEW-H127-03P. Anillo abierto plateado con forma de estrella de circonita cúbica transparente, enchapado, platino, talla estadounidense 6.5-7. C$255.00.png'),
            descripcion: "Anillo abierto plateado con forma de estrella de circonita cúbica transparente, enchapado, platino, talla estadounidense 6.5-7.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 12,
            referencia: "ref: GBR-RJEW-H127-31G",
            nombre: "Anillo abierto",
            precio: "C$255.00",
            imagen: require('@/assets/img/anillos/anillos_oro/12-GBR-RJEW-H127-31G. Anillo abierto con forma de rama de circonita cúbica transparente, enchapado, dorado, diámetro interior 17,6 mm. C$255.00.png'),
            descripcion: "Anillo abierto con forma de rama de circonita cúbica transparente, enchapado, dorado, diámetro interior 17,6 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 13,
            referencia: "ref: GBR-RJEW-H127-31P",
            nombre: "Anillo plateado",
            precio: "C$255.00",
            imagen: require('@/assets/img/anillos/anillos_oro/13-GBR-RJEW-H127-31P. Anillo plateado abierto con formas de rama de circonita cúbica transparente, enchapado, platino, diámetro interior 17,6 mm. C$255.00.png'),
            descripcion: "Anillo plateado abierto con formas de rama de circonita cúbica transparente, enchapado, platino, diámetro interior 17,6 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 14,
            referencia: "ref: GBR-RJEW-H127-34P",
            nombre: "Anillo plateado",
            precio: "C$245.00",
            imagen: require('@/assets/img/anillos/anillos_oro/14-GBR-RJEW-H127-34P. Anillo plateado abierto con circonita cúbica transparente y diseño de infinito, enchapado, platino, diámetro interior talla 7 de EE. UU. C$245.00.png'),
            descripcion: "Anillo plateado abierto con circonita cúbica transparente y diseño de infinito, enchapado, platino, diámetro interior talla 7 de EE. UU.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 15,
            referencia: "ref: GBR-RJEW-K240-06P",
            nombre: "Anillo ajustable",
            precio: "C$315.00",
            imagen: require('@/assets/img/anillos/anillos_oro/15-GBR-RJEW-K240-06P. Anillo ajustable con formas de corazón de circonita cúbica transparente, enchapado, platino, diámetro interior 18 mm. C$315.00.png'),
            descripcion: "Anillo ajustable con formas de corazón de circonita cúbica transparente, enchapado, platino, diámetro interior 18 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 16,
            referencia: "ref: GBR-RJEW-P015-01P",
            nombre: "Anillos plateados",
            precio: "C$280.00",
            imagen: require('@/assets/img/anillos/anillos_oro/16-GBR-RJEW-P015-01P. Anillos plateados abiertos, chapado de larga duración, serpiente, platino, tamaño 7, diámetro interior 17 mm. C$280.00.png'),
            descripcion: "Anillos plateados abiertos, chapado de larga duración, serpiente, platino, tamaño 7, diámetro interior 17 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 17,
            referencia: "ref: GBR-RJEW-P034-10G",
            nombre: "Anillo abierto",
            precio: "C$285.00",
            imagen: require('@/assets/img/anillos/anillos_oro/17-GBR-RJEW-P034-10G. Anillo abierto con corona de circonita cúbica transparente, chapado en oro real de 18 quilates, talla estadounidense 6.5-7. C$285.00.png'),
            descripcion: "Anillo abierto con corona de circonita cúbica transparente, chapado en oro real de 18 quilates, talla estadounidense 6.5-7.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 18,
            referencia: "ref: GBR-RJEW-P034-10P",
            nombre: "Anillo plateado",
            precio: "C$285.00",
            imagen: require('@/assets/img/anillos/anillos_oro/18-GBR-RJEW-P034-10P. Anillo plateado abierto con corona de circonita cúbica transparente, enchapado, platino, talla estadounidense 6.5-7. C$285.00.png'),
            descripcion: "Anillo plateado abierto con corona de circonita cúbica transparente, enchapado, platino, talla estadounidense 6.5-7.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
          {
            id: 19,
            referencia: "ref: GBR-RJEW-S044-003",
            nombre: "Anillos enchapado",
            precio: "C$275.00",
            imagen: require('@/assets/img/anillos/anillos_oro/19-GBR-RJEW-S044-003. Anillos enchapado ajustables con micropavé de circonita cúbica, dorados, tamaño 8, 18 mm. C$275.00.png'),
            descripcion: "Anillos enchapado ajustables con micropavé de circonita cúbica, dorados, tamaño 8, 18 mm.",
            seccion: "sección anillos oro",
            cantidad: 1
          },
        ],
        mostrarModalCarrito: false,
        mostrarModalInfo: false,
        productoSeleccionado: null,
      }
    },
    methods: {
      incrementarCantidad(producto) {
        producto.cantidad++;
      },
      decrementarCantidad(producto) {
        if (producto.cantidad > 1) {
          producto.cantidad--;
        }
      },
      agregarAlCarrito(producto) {
        this.$store.commit('agregarAlCarrito', { ...producto });
        this.mostrarModalCarrito = true;
        setTimeout(() => {
          this.cerrarModalCarrito();
        }, 1000); // Modal se cierra automáticamente después de 1 segundo
      },
      abrirModalInfo(producto) {
        this.productoSeleccionado = producto;
        this.mostrarModalInfo = true;
      },
      cerrarModalCarrito() {
        this.mostrarModalCarrito = false;
      },
      cerrarModalInfo() {
        this.mostrarModalInfo = false;
        this.productoSeleccionado = null;
      }
    }
  }
  </script>
  
  
  
  <style lang="scss">
  @import "@/assets/styles/stylesProductos.scss";
  
  .contenedor-titulos{
          position: relative;
          display: inline-block;
          text-align: center;
          display: flex;
          justify-content: center;
  
          img{
              height: 8rem;
              width: 40rem;
          }
  
          .texto{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
  
              h2{
                  color: $violeta-titulos;
                  // font-family: 'Roboto', sans-serif;
                  font-size: 2.8rem;
                  // font-family: 'Crete Round', serif;
                  font-family: 'Lobster', cursive;
                  font-weight: 300;
                  letter-spacing: 1px;
              }
          }
  
      }
  
  .productos-container {
    padding: 10px;
  
    .productos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  
      .producto {
        margin: 20px;
  
        .img-container {
          position: relative;
  
          img {
            height: 29rem;
            width: 24rem;
            object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
          }
  
          .icon-eye {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
  
            i {
              color: #fff;
              font-size: 18px;
            }
          }
        }
  
        // .ref, .nombre-producto, .precio, .cantidad, button {
        //   text-align: center;
        //   font-size: 16px;
        // }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding-top: 130px;
  
      .modal-content {
        background-color: #fff;
        padding: 35px 13px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        max-width: 350px;
        width: 90%;
        // margin-top: 100px;
  
        p{
          color: $violeta;
          font-weight:700;
          font-size: 1.7rem;
          margin: -.5rem 0 .5rem 0;
        }
  
        li{
          font-size: 1.5rem;
        }
  
        img {
          width: 80%;
          height: 30rem;
          margin-bottom: 15px;
          object-fit: cover;
          // display: none;
        }
  
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  
    @media (max-width: 768px) {
      .productos {
        flex-direction: column;
        align-items: center;
  
        .producto {
          width: 100%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              padding: 3px;
              i {
                font-size: 16px;
              }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal .modal-content {
        width: 95%;
        padding: 15px;
      }
    }
  
    @media (max-width: 700px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              // padding: 2px;
              // i {
              //   font-size: 16px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal{
        padding-top: 80px;
  
        .modal-content {
          width: 100%;
          padding: 10px;
  
        p{
          font-size: 2rem;
        }  
  
        li{
          margin: 1rem 0;
          font-size: 1.8rem;
        }
      }
      } 
    }
  
    @media (max-width: 450px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 5px 0;
  
          .img-container {
            .icon-eye {
              bottom: 3px;
              right: 3px;
              // padding: 2px;
              // i {
              //   font-size: 14px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 12px;
          }
        }
      }
    }
  
    @media (max-width: 400px){
      .modal .modal-content{
        width: 70%;
  
        img{
        width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
        height: 25rem;
        padding:2rem 0 .5rem 0;
      }
      } 
    }
  }
  </style>