<!-- <template>
  <form @submit.prevent="submitForm">
    <input type="text" v-model="nombre" placeholder="Tu nombre" required />
    <input type="email" v-model="correo" placeholder="Tu correo" required />
    <textarea v-model="mensaje" placeholder="Tu mensaje" required></textarea>
    <button type="submit">Enviar</button>
  </form>
</template> -->

<template>
  <div class="titulos4">
    <div class="contenedor-titulos scroll-up">
      <img src="../assets/img/fondo-titulos.png" alt="">
      <div class="texto">
        <h2>Dèjanos tu mensaje</h2>
      </div>
    </div>

    <div class="color-fondo">
      <form @submit.prevent="submitForm" class="form-content">
        <div>
          <input type="text" v-model="nombre" placeholder="Nombre Completo" required />
        </div>
        <div>
          <input type="email" v-model="correo" placeholder="Correo Electrónico" required />
        </div>
        <div>
          <textarea v-model="mensaje" placeholder="Mensaje" required></textarea>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      nombre: '',
      correo: '',
      mensaje: ''
    };
  },
  methods: {
    submitForm() {
      axios.post('/scripts/enviar-correo.php', {
        nombre: this.nombre,
        correo: this.correo,
        mensaje: this.mensaje
      })
      .then(response => {
        console.log('Correo enviado con éxito');
        alert('Correo enviado con éxito');
      })
      .catch(error => {
        console.error('Error al enviar el correo:', error);
        alert('Error al enviar el correo');
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/normalize.css";
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/fonts.scss";

.titulos4 {
  background-image: url(../assets/img/fondito.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0.8rem 3%;

  .color-fondo {
    background: linear-gradient(to bottom, #f2d6f2b5, #f6f0f6e1);
    display: flex;
    justify-content: center;
    
    .form-content {
      background-color: white;
      padding: 20px 40px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      max-width: 400px;
      width: 100%;
      margin: 3.5rem 0;

      button {
        width: 100%;
        padding: 10px;
        background: linear-gradient(to right, #9a258e, #f5c6f8);
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }

      input, textarea {
        width: 100%;
        padding: 10px 5px;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 1.3rem;
        color: $violeta-titulos;
      }
    }
  }

  .contenedor-titulos {
    position: relative;
    display: inline-block;
    text-align: center;
    display: flex;
    justify-content: center;

    img {
      height: 8rem;
      width: 40rem;
    }

    .texto {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      h2 {
        color: $violeta-titulos;
        font-size: 2.8rem;
        font-family: 'Lobster', cursive;
        font-weight: 300;
        letter-spacing: 1px;
      }
    }
  }
}

@media (max-width: 768px) {
  .titulos4 {
    .contenedor-titulos {
      img {
        width: 100%;
        height: auto;
      }

      .texto {
        h2 {
          font-size: 2rem;
        }
      }
    }

    .color-fondo {
      .form-content {
        padding: 15px 20px;
        max-width: 100%;

        input, textarea {
          font-size: 1rem;
        }

        button {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .titulos4 {
    .contenedor-titulos {
      img {
        width: 100%;
        height: auto;
      }

      .texto {
        h2 {
          font-size: 1.5rem;
        }
      }
    }

    .color-fondo {
      .form-content {
        padding: 25px 15px;
        max-width: 270px;

        input, textarea {
          font-size: 1.4rem;
          padding: 1.5rem 0 1.5rem .8rem;
        }

        button {
          font-size: 11px;
        }
      }
    }
  }
}
</style>
