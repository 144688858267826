<template>
    <navCategoria/>
    <Enlaces/>
  
    <div class="productos-container">
      <!-- <h1>{{ titulo }}</h1> -->
      <div class="contenedor-titulos scroll-up">
              <img src="@/assets/img/fondo-titulos.png" alt="">
              <div class="texto"><h2>{{titulo}}</h2></div>
      </div>
      <div class="productos">
        <div class="producto" v-for="producto in productos" :key="producto.id">
          <div class="img-container">
            <img :src="producto.imagen" :alt="producto.nombre">
            <div class="icon-eye" @click="abrirModalInfo(producto)">
              <i class="bx bx-show"></i>
            </div>
          </div>
          <p class="ref">{{ producto.referencia }}</p>
          <p class="nombre-producto">{{ producto.nombre }}</p>
          <p class="precio">{{ producto.precio }}</p>
          <div class="cantidad">
            <button @click="decrementarCantidad(producto)">-</button>
            <span>{{ producto.cantidad }}</span>
            <button @click="incrementarCantidad(producto)">+</button>
          </div>
          <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
        </div>
      </div>
      
      <!-- Modal para agregar al carrito -->
      <div v-if="mostrarModalCarrito" class="modal">
        <div class="modal-content">
          <!-- <span class="close" @click="cerrarModalCarrito">&times;</span> -->
          <p>Producto agregado al carrito</p>
        </div>
      </div>
      
      <!-- Modal para información del producto -->
      <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
        <div class="modal-content">
          <span class="close" @click="cerrarModalInfo">&times;</span>
          <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
          <p>Descripción</p>
          <li>{{ productoSeleccionado.descripcion }}</li>
        </div>
      </div>
    </div>
    <Footer/> 
  </template>
  
  
  
  
  <script>
  import NavCategoria from '@/components/navCategoria.vue'
  import Enlaces from '@/components/enlaces.vue'
  import Footer from '@/components/footer.vue'
  
  export default {
    components: {
      NavCategoria,
      Enlaces,
      Footer,
    },
    data() {
      return {
        titulo: "Collares en Acero Inoxidable y dijes enchapados",
        productos: [
          {
            id: 1,
            referencia: "ref: GBR-RJEW-F080-05P",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/1-GBR-CAI-CORA-GOLD-00001. Collar de Acero Inoxidable con dije enchapado, Corazón doble. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón doble.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 2,
            referencia: "ref: GBR-CHS-I003-V01-A-G-P",
            nombre: "Collar de Acero",
            precio: "C$205.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/2-GBR-CHS-I003-V01-A-G-P. Collar de Acero Inoxidable con perla blanca porcelana. C$205.00.png'),
            descripcion: "Collar de Acero Inoxidable con perla blanca porcelana.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 3,
            referencia: "ref: GBR-DIY-S031-105A",
            nombre: "Collar de Acero",
            precio: "C$355.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/3-GBR-DIY-S031-105A. Collar de Acero Inoxidable con dije enchapado, Corazón doble cara. C$355.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón doble cara.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 4,
            referencia: "ref: GBR-KK-D004-14G",
            nombre: "Collar de Acero",
            precio: "C$345.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/4-GBR-KK-D004-14G. Collar de Acero Inoxidable con dije enchapado, flor que se abre (You_re my ray of sunshine). C$345.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, flor que se abre (You_re my ray of sunshine).",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 5,
            referencia: "ref: GBR-KK-D026-33G",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/5-GBR-KK-D026-33G. Collar de Acero Inoxidable con dije enchapado, Tulipán dorado y verde. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Tulipán dorado y verde.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 6,
            referencia: "ref: GBR-KK-E003-10G",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/6-GBR-KK-E003-10G. Collar de Acero Inoxidable con dije enchapado, Corazones dobles crema y brillante. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazones dobles crema y brillante.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 7,
            referencia: "ref: GBR-KK-I657-32G-B",
            nombre: "Collar de Acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/7-GBR-KK-I657-32G-B. Collar en Acero Inoxidable con dije enchapado en Oro, Virgen de Guadalupe rodeada de perlas blancas. C$255.00.png'),
            descripcion: "Collar en Acero Inoxidable con dije enchapado en Oro, Virgen de Guadalupe rodeada de perlas blancas.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 8,
            referencia: "ref: GBR-KK-N227-80-NF",
            nombre: "Collar de Acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/8-GBR-KK-N227-80-NF. Collar de Acero Inoxidable con dije enchapado, Mariposas minimalistas colores. C$255.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Mariposas minimalistas colores.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 9,
            referencia: "ref: GBR-KK-O126-13G",
            nombre: "Collar de Acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/9-GBR-KK-O126-13G.  Collar de Acero Inoxidable con dije enchapado en oro, Saturno. C$225.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado en oro, Saturno.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 10,
            referencia: "ref: GBR-KK-Q278-004-NF",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/10-GBR-KK-Q278-004-NF. Collar de Acero inoxidable con dije enchapado, Tulipán. C$265.00.png'),
            descripcion: "Collar de Acero inoxidable con dije enchapado, Tulipán.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 11,
            referencia: "ref: GBR-KK-S354-322-NF",
            nombre: "Collar de Acero",
            precio: "C$345.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/11-GBR-KK-S354-322-NF. Collar en Acero Inoxidable con dije enchapado en oro de flor doble de 4 pétalos, dorada y crema. C$345.00.png'),
            descripcion: "Collar en Acero Inoxidable con dije enchapado en oro de flor doble de 4 pétalos, dorada y crema.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 12,
            referencia: "ref: GBR-KK-S354-324-NF",
            nombre: "Collar de Acero",
            precio: "C$335.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/12-GBR-KK-S354-324-NF. Collar de Acero Inoxidable con dije enchapado en oro, Triple Jasmín. C$335.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado en oro, Triple Jasmín.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 13,
            referencia: "ref: GBR-NJEW-F195-01B-G-CROSS",
            nombre: "Collar de Acero",
            precio: "C$465.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/13-GBR-NJEW-F195-01B-G-CROSS. Collar de Acero Inoxidable estilo serpiente y dije enchapado en oro , Cruz. C$465.00.png'),
            descripcion: "Collar de Acero Inoxidable estilo serpiente y dije enchapado en oro , Cruz.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 14,
            referencia: "ref: GBR-X-KK-S354-079-NF",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/14-GBR-X-KK-S354-079-NF. Collar de Acero Inoxidable con dije enchapado, Girasol. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Girasol.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 15,
            referencia: "ref: GBR-X-KK-S364-014A",
            nombre: "Collar de Acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/15-GBR-X-KK-S364-014A. Collar de Acero inoxidable con dije enchapado, Rosa Rosa. C$255.00.png'),
            descripcion: "Collar de Acero inoxidable con dije enchapado, Rosa Rosa.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 16,
            referencia: "ref: GBR-ZIRC-A019-14G-AZ",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/16-GBR-ZIRC-A019-14G-AZ. Collar de Acero Inoxidable con dije enchapado, Corazón Azul. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón Azul.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 17,
            referencia: "ref: GBR-ZIRC-A019-14G-CE",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/17-GBR-ZIRC-A019-14G-CE. Collar de Acero Inoxidable con dije enchapado, Corazón Celeste. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón Celeste.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 18,
            referencia: "ref: GBR-ZIRC-A019-14G-MOR",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/18-GBR-ZIRC-A019-14G-MOR. Collar de Acero Inoxidable con dije enchapado, Corazón Morado. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón Morado.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 19,
            referencia: "ref: GBR-ZIRC-A019-14G-ROJO",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/19-GBR-ZIRC-A019-14G-ROJO. Collar de Acero Inoxidable con dije enchapado, Corazón Rojo. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón Rojo.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 20,
            referencia: "ref: GBR-ZIRC-C040-46G-01",
            nombre: "Collar de Acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/20-GBR-ZIRC-C040-46G-01. Collar de acero inoxidable con dije chapado en oro real de 18 quilates, cruz cruciforme, colorido. C$295.00.png'),
            descripcion: "Collar de acero inoxidable con dije chapado en oro real de 18 quilates, cruz cruciforme, colorido.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 21,
            referencia: "ref: GBR-ZIRC-E165-04G",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/21-GBR-ZIRC-E165-04G. Collar de Acero Inoxidable con dije enchapado, Media luna y perla. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Media luna y perla.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 22,
            referencia: "ref: GBR-ZIRC-F069-22G-BLUE",
            nombre: "Collar de Acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/22GBR-ZIRC-F069-22G-BLUE. Collar de Acero Inoxidable, con dije enchapado en oro, corona dorada, flor azul y blanco. C$295.00.png'),
            descripcion: "Collar de Acero Inoxidable, con dije enchapado en oro, corona dorada, flor azul y blanco.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 23,
            referencia: "ref: GBR-ZIRC-F069-22G-K082-043A",
            nombre: "Collar de Acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/23-GBR-ZIRC-F069-22G-K082-043A. Collar de Acero Inoxidable con dije enchapado, Rosa Amarilla y Corona dorada. C$295.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Rosa Amarilla y Corona dorada.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 24,
            referencia: "ref: GBR-ZIRC-F069-22G-Q014-093G-06",
            nombre: "Collar de Acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/24-GBR-ZIRC-F069-22G-Q014-093G-06. Collar de Acero Inoxidable con dije enchapado, Corona y Tulipán Rosa. C$295.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corona y Tulipán Rosa.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 25,
            referencia: "ref: GBR-ZIRC-F129-34-G",
            nombre: "Collar de Acero",
            precio: "C$250.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/25-GBR-ZIRC-F129-34-G. Collar de Acero Inoxidable con dije enchapado, Flores. C$250.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Flores.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 26,
            referencia: "ref: GBR-ZIRC-F129-52G",
            nombre: "Collar de Acero",
            precio: "C$305.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/26-GBR-ZIRC-F129-52G. acero inoxidable con dije deslizantes de colores chapado de larga duración. flor con con corazón. C$305.00.png'),
            descripcion: "collar en acero inoxidable con dije deslizantes de colores chapado de larga duración. flor con con corazón.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 27,
            referencia: "ref: GBR-ZIRC-F133-04G",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/27-GBR-ZIRC-F133-04G. Collar de Acero Inoxidable con dije enchapado, Tulipán sin tallo. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Tulipán sin tallo.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 28,
            referencia: "ref: GBR-ZIRC-G152-18B-G",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/28-GBR-ZIRC-G152-18B-G. Collar de Acero Inoxidable con dije enchapado en oro, Cruz y perlas. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado en oro, Cruz y perlas.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 29,
            referencia: "ref: GBR-ZIRC-G161-22",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/29-GBR-ZIRC-G161-22. Collar de Acero Inoxidable y dije enchapado, Flor de Loto Negro. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable y dije enchapado, Flor de Loto Negro.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 30,
            referencia: "ref: GBR-ZIRC-I046-15A",
            nombre: "Collar de Acero",
            precio: "C$225.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/30-GBR-ZIRC-I046-15A. Collar de Acero Inoxidable con dije enchapado, Mariposa doble rosa. C$225.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Mariposa doble rosa.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 31,
            referencia: "ref: GBR-ZIRC-I046-15G",
            nombre: "Collar de Acero",
            precio: "C$225.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/31-GBR-ZIRC-I046-15G. Collar de Acero Inoxidable con dije enchapado, Mariposa doble Negro. C$225.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Mariposa doble Negro.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 32,
            referencia: "ref: GBR-ZIRC-I047-03",
            nombre: "Collar de Acero",
            precio: "C$225.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/32-GBR-ZIRC-I047-03. Collar de Acero Inoxidable con dije enchapado, Mariposas colores. C$225.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Mariposas colores.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 33,
            referencia: "ref: GBR-ZIRC-I055-02G",
            nombre: "Collar de Acero",
            precio: "C$335.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/33-GBR-ZIRC-I055-02G. Collar en acero inoxidable con dije enchapado en oro de grisaol dorado giratorio. C$335.00.png'),
            descripcion: "Collar en acero inoxidable con dije enchapado en oro de grisaol dorado giratorio.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 34,
            referencia: "ref: GBR-ZIRC-I063-38G-AZ",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/34-GBR-ZIRC-I063-38G-AZ. Collar de Acero Inoxidable con dije enchapado, corazón centro azul. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, corazón centro azul.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 35,
            referencia: "ref: GBR-ZIRC-I063-38G-ROS",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/35-GBR-ZIRC-I063-38G-ROS. Collar de Acero Inoxidable con dije enchapado, corazón centro rosado. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, corazón centro rosado.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 36,
            referencia: "ref: GBR-ZIRC-I063-38G-VER",
            nombre: "Collar de Acero",
            precio: "C$285.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/36-GBR-ZIRC-I063-38G-VER. Collar de Acero Inoxidable con dije enchapado, corazón centro verde. C$285.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, corazón centro verde.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 37,
            referencia: "ref: GBR-ZIRC-K082-043A",
            nombre: "Collar de Acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/37-GBR-ZIRC-K082-043A. Collar de Acero Inoxidable con dije enchapado, Rosa amarilla. C$255.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Rosa amarilla.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 38,
            referencia: "ref: GBR-ZIRC-L093-71G",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/38-GBR-ZIRC-L093-71G. Collar de Acero Inoxidable con dije enchapado, MAMÁ. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, MAMÁ.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 39,
            referencia: "ref: GBR-ZIRC-R014-31",
            nombre: "Collar de Acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/39-GBR-ZIRC-R014-31. Collar de Acero Inoxidable con dije enchapado, Delfin doble perla morada. C$255.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Delfin doble perla morada.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 40,
            referencia: "ref: GBR-ZIRC-R014-53",
            nombre: "Collar de Acero",
            precio: "C$250.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/40-GBR-ZIRC-R014-53. Collar de Acero Inoxidable con dije enchapado, Delfin doble perla blanca. C$250.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Delfin doble perla blanca.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 41,
            referencia: "ref: GBR-ZIRC-Z007-11G",
            nombre: "Collar de Acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/41-GBR-ZIRC-Z007-11G. Collar de Acero Inoxidable con dije enchapado, Corazón Abu. C$265.00.png'),
            descripcion: "Collar de Acero Inoxidable con dije enchapado, Corazón Abu.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 42,
            referencia: "ref: GBR-KK-A160-14G",
            nombre: "Collar de Acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/42-GBR-KK-A160-14G. Collar en acero inoxidable con baño de micro pavé de circonitas cúbicas, con perla, chapado de larga duración, chapado en oro real de 18 quilates, oso, rojo. C$295.00.jpg'),
            descripcion: "Collar en acero inoxidable con baño de micro pavé de circonitas cúbicas, con perla, chapado de larga duración, chapado en oro real de 18 quilates, oso, rojo.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 43,
            referencia: "ref: GBR-NJEW-G074-58G-B",
            nombre: "Collar con colgante",
            precio: "C$325.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/43-GBR-NJEW-G074-58G-B. Collar con colgante de letra inicial V de concha sintética, joyeria de acero inoxidable 304 chapado en oro para mujer, centro negro. C$325.00.jpg'),
            descripcion: "Collar con colgante de letra inicial V de concha sintética, joyeria de acero inoxidable 304 chapado en oro para mujer, centro negro.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 44,
            referencia: "ref: GBR-NJEW-G074-58G-V",
            nombre: "Collar con colgante",
            precio: "C$325.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/44-GBR-NJEW-G074-58G-V. Collar con colgante de letra inicial V de concha sintética, joyeria de acero inoxidable 304 chapado en oro para mujer, centro verde. C$325.00.jpg'),
            descripcion: "Collar con colgante de letra inicial V de concha sintética, joyeria de acero inoxidable 304 chapado en oro para mujer, centro verde.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 45,
            referencia: "ref: GBR-NJEW-G074-58G-W",
            nombre: "Collar con colgante",
            precio: "C$325.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/45-GBR-NJEW-G074-58G-W. Collar con colgante de letra inicial V de concha sintética, joyeria de acero inoxidable 304 chapado en oro para mujer, centro negro. C$325.00.jpg'),
            descripcion: "Collar con colgante de letra inicial V de concha sintética, joyeria de acero inoxidable 304 chapado en oro para mujer, centro negro.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 46,
            referencia: "ref: GBR-NJEW-I104-13A",
            nombre: "Collares de tenis",
            precio: "C$339.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/46-GBR-NJEW-I104-13A. Collares de tenis, con cadena de copa de circonita cúbicas y cierres de pinza de langosta, transparente, 13 pulgada (33 cm) C$339.00.jpg'),
            descripcion: "Collares de tenis, con cadena de copa de circonita cúbicas y cierres de pinza de langosta, transparente, 13 pulgada (33 cm)",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 47,
            referencia: "ref: GBR-ZIRC-C004-02G",
            nombre: "Collar de acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/47-GBR-ZIRC-C004-02G. Collar de acero inoxidable con micropavé de circonitas cúbicas, dije de cabeza de perro, chapado en oro real de 18 quilates , 12.5x13x3mm. C$295.00.jpg'),
            descripcion: "Collar de acero inoxidable con micropavé de circonitas cúbicas, dije de cabeza de perro, chapado en oro real de 18 quilates , 12.5x13x3mm.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
          {
            id: 48,
            referencia: "ref: GBR-ZIRC-C040-46G-01",
            nombre: "Collar en acero",
            precio: "C$295.00",
            imagen: require('@/assets/img/collares/collares_en_acero_inoxidable_y_dijes_enchapados/48-GBR-ZIRC-C040-46G-01. Collar en acero inoxidable con dije chapado de largo duración, chapado en oro real de 18k, cruz de colores. C$295.00.jpg'),
            descripcion: "Collar en acero inoxidable con dije chapado de largo duración, chapado en oro real de 18k, cruz de colores.",
            seccion: "sección collares en acero inoxidable y dijes enchapados",
            cantidad: 1
          },
        ],
        mostrarModalCarrito: false,
        mostrarModalInfo: false,
        productoSeleccionado: null,
      }
    },
    methods: {
      incrementarCantidad(producto) {
        producto.cantidad++;
      },
      decrementarCantidad(producto) {
        if (producto.cantidad > 1) {
          producto.cantidad--;
        }
      },
      agregarAlCarrito(producto) {
        this.$store.commit('agregarAlCarrito', { ...producto });
        this.mostrarModalCarrito = true;
        setTimeout(() => {
          this.cerrarModalCarrito();
        }, 1000); // Modal se cierra automáticamente después de 1 segundo
      },
      abrirModalInfo(producto) {
        this.productoSeleccionado = producto;
        this.mostrarModalInfo = true;
      },
      cerrarModalCarrito() {
        this.mostrarModalCarrito = false;
      },
      cerrarModalInfo() {
        this.mostrarModalInfo = false;
        this.productoSeleccionado = null;
      }
    }
  }
  </script>
  
  
  
  <style lang="scss">
  @import "@/assets/styles/stylesProductos.scss";
  
  .contenedor-titulos{
          position: relative;
          display: inline-block;
          text-align: center;
          display: flex;
          justify-content: center;
  
          img{
              height: 8rem;
              width: 40rem;
          }
  
          .texto{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
  
              h2{
                  color: $violeta-titulos;
                  // font-family: 'Roboto', sans-serif;
                  font-size: 2.8rem;
                  // font-family: 'Crete Round', serif;
                  font-family: 'Lobster', cursive;
                  font-weight: 300;
                  letter-spacing: 1px;
              }
          }
  
      }
  
  .productos-container {
    padding: 10px;
  
    .productos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  
      .producto {
        margin: 20px;
  
        .img-container {
          position: relative;
  
          img {
            height: 29rem;
            width: 24rem;
            object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
          }
  
          .icon-eye {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
  
            i {
              color: #fff;
              font-size: 18px;
            }
          }
        }
  
        // .ref, .nombre-producto, .precio, .cantidad, button {
        //   text-align: center;
        //   font-size: 16px;
        // }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding-top: 130px;
  
      .modal-content {
        background-color: #fff;
        padding: 35px 13px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        max-width: 350px;
        width: 90%;
        // margin-top: 100px;
  
        p{
          color: $violeta;
          font-weight:700;
          font-size: 1.7rem;
          margin: -.5rem 0 .5rem 0;
        }
  
        li{
          font-size: 1.5rem;
        }
  
        img {
          width: 80%;
          height: 30rem;
          margin-bottom: 15px;
          object-fit: cover;
          // display: none;
        }
  
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  
    @media (max-width: 768px) {
      .productos {
        flex-direction: column;
        align-items: center;
  
        .producto {
          width: 100%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              padding: 3px;
              i {
                font-size: 16px;
              }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal .modal-content {
        width: 95%;
        padding: 15px;
      }
    }
  
    @media (max-width: 700px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              // padding: 2px;
              // i {
              //   font-size: 16px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal{
        padding-top: 80px;
  
        .modal-content {
          width: 100%;
          padding: 10px;
  
        p{
          font-size: 2rem;
        }  
  
        li{
          margin: 1rem 0;
          font-size: 1.8rem;
        }
      }
      } 
    }
  
    @media (max-width: 450px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 5px 0;
  
          .img-container {
            .icon-eye {
              bottom: 3px;
              right: 3px;
              // padding: 2px;
              // i {
              //   font-size: 14px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 12px;
          }
        }
      }
    }
  
    @media (max-width: 400px){
      .modal .modal-content{
        width: 70%;
  
        img{
        width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
        height: 25rem;
        padding:2rem 0 .5rem 0;
      }
      } 
    }
  }
  </style>