// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/home.vue';
import TodasLasCategorias from '../components/TodasLasCategorias.vue';

import Aretes from '../components/categorias/aretes.vue';
// import Pulseras from '../components/categorias/pulseras.vue';

import PulserasPlatino from '../components/categorias/pulserasPlatino.vue';
import PulserasAcero from '../components/categorias/pulserasAcero.vue';
import PulserasHilo from '../components/categorias/pulserasHilo.vue';

import AnillosOro from '../components/categorias/AnillosOro.vue';
import AnillosAcero from '../components/categorias/AnillosAcero.vue';

import collaresYDijes from '../components/categorias/collaresYDijes.vue';
import collaresParejas from '../components/categorias/collaresParejas.vue';
import collaresPerlasNaturales from '../components/categorias/collaresPerlasNaturales.vue';
import collaresDijesCristales from '../components/categorias/collaresDijesCristales.vue';
import collaresCuarzos from '../components/categorias/collaresCuarzos.vue';
import collaresDijesEnchapados from '../components/categorias/collaresDijesEnchapados.vue';

import Conjuntos from '../components/categorias/conjuntos.vue';

import charmsPulseraEuropeo from '../components/categorias/charmsPulseraEuropeo.vue';
import charmsPulserasSerpiente from '../components/categorias/charmsPulserasSerpiente.vue';
import charmsPerlasColores from '../components/categorias/charmsPerlasColores.vue';

import tarjetasDedicatorias from '../components/categorias/tarjetasDedicatorias.vue';
import llaverosResina from '../components/categorias/llaverosResina.vue';
import cajitasDeFormas from '../components/categorias/cajitasDeForma.vue';
import cajitasSencillas from '../components/categorias/cajitasSencillas.vue';
import joyeros from '../components/categorias/joyeros.vue';


import Carrito from '../components/categorias/carrito.vue';

const routes = [
  { path: '/', name: 'Home', component: Home},
  { path: '/categorias', name: 'TodasLasCategorias', component: TodasLasCategorias},
  { path: '/home', name: 'home', component: Home},

  { path: '/aretes', component: Aretes },
  
  { path: '/pulseras/platino', component: PulserasPlatino },
  { path: '/pulseras/acero', component: PulserasAcero },
  { path: '/pulseras/hilo', component: PulserasHilo },

  { path: '/anillos/oro', component: AnillosOro },
  { path: '/anillos/acero', component: AnillosAcero },

  { path: '/collares/collares_y_dijes', component: collaresYDijes },
  { path: '/collares/collares_en_parejas', component: collaresParejas },
  { path: '/collares/collares_y_perlas_naturales', component: collaresPerlasNaturales },
  { path: '/collares/collares_y_dijes_cristales', component: collaresDijesCristales },
  { path: '/collares/collares_y_cuarzos', component: collaresCuarzos },
  { path: '/collares/collares_y_dijes_enchapados', component: collaresDijesEnchapados },

  { path: '/conjuntos', component: Conjuntos },

  { path: '/charms/charms_pulseras_europeo', component: charmsPulseraEuropeo },
  { path: '/charms/charms_pulserasSerpiente', component: charmsPulserasSerpiente },
  { path: '/charms/charms_perlas_colores', component: charmsPerlasColores },

  { path: '/accesorios/tarjetas_dedicatorias', component: tarjetasDedicatorias },
  { path: '/accesorios/llaveros_resina', component: llaverosResina },
  { path: '/accesorios/cajitas_de_formas', component: cajitasDeFormas },
  { path: '/accesorios/cajitas_sencillas', component: cajitasSencillas },
  { path: '/accesorios/joyeros', component: joyeros },

  { path: '/carrito', component: Carrito },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Cambiar el título de la pestaña en todas las rutas
router.beforeEach((to, from, next) => {
  document.title = 'Glamour By Ramos';  // Título fijo para todas las rutas
  next();
});

export default router;
