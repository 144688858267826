<template>

<section class="contactos">
    <div id="contactanos"></div>
    <div class="titulos4">
        <div class="contenedor-titulos scroll-up">
            <img src="../assets/img/fondo-titulos.png" alt="">
            <div class="texto"><h2>Servicio al cliente</h2></div>
        </div>
    </div>

        <div class="datos-contactos">
            <div class="horarios">
                <h3>Horarios</h3>
                <span>Lunes a Viernes</span>
                <span>9:00am - 6:00pm</span>
                <span>Sábados</span>
                <span>9:00am - 5:00pm</span>
            </div>

            <div class="contacto">
                <h3>Contactos</h3>
                <span>+50587617146</span>
                <!-- <span>ejemplo@hotmail.com</span> -->
                <span>Managua - Nicaragua</span>
                <span>Plaza Corona, Colectivo Corals</span>
                <span>ventas@glamourbyramos.com</span>
            </div>
        </div>

        <div class="dudas">
            <div class="texto-dudas">
                <h3>Dudas</h3>
                <span>Si tienes dudas sobre nuestros productos y servicios puedes contactarnos en las siguientes redes sociales.</span>
                <div class="iconos-redes">
                    <a href="https://www.facebook.com/GlamourbyRamos17/" target="_blank"><i class='bx bxl-facebook'></i></a>
                    <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><i class='bx bxl-instagram' ></i></a>
                    <a href="https://api.whatsapp.com/send/?phone=%2B50587617146"><i class='bx bxl-whatsapp' ></i></a>
                </div>
            </div>
        </div>
   </section>
    
</template>

<script>
</script>

<style lang="scss" >
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";

    .titulos4{
    background-image: url(../assets/img/fondito.png);
    background-position: top;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: cover ;
    padding:0.8rem 3%;
    
    .contenedor-titulos{
        position: relative;
        display: inline-block;
        text-align: center;
        display: flex;
        justify-content: center;

        img{
            height: 8rem;
            width: 40rem;
        }

        .texto{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            h2{
                color: $violeta-titulos;
                // font-family: 'Roboto', sans-serif;
                font-size: 2.8rem;
                // font-family: 'Crete Round', serif;
                font-family: 'Lobster', cursive;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }
    }
}

    //contactos
.contactos{
    padding: 2rem 0 0 0;

    .datos-contactos{
        margin-top: 1.5rem;
        padding: 5rem 6% 2rem 6%;
        display: flex;
        justify-content: space-around;
        background: #F8F2FC;

        .horarios{
            display: flex;
            flex-direction: column;

            h3{
                font-size: 2.4rem;
                margin-bottom: .5rem;
                color: $violeta-titulos;
            }

            span{
                margin-top: .5rem;
                font-size: 1.9rem;
                color: $violeta-titulos;
                line-height: 2.5rem;
                letter-spacing: .1rem;
            }
        }

        .contacto{
            display: flex;
            flex-direction: column;

            h3{
                font-size: 2.4rem;
                margin-bottom: .5rem;
                color: $violeta-titulos;
            }

            span{
                margin-top: .5rem;
                font-size: 1.9rem;
                color: $violeta-titulos;
                line-height: 2.5rem;
                letter-spacing: .1rem;
            }
        }

    }

    .dudas .texto-dudas{
        padding: 0 0 5rem 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #F8F2FC;

        h3{
            font-size: 2.4rem;
            margin-bottom: .5rem;
            color: $violeta-titulos;
        }

        span{
            margin-top: .5rem;
            font-size: 1.9rem;
            color: $violeta-titulos;
            width: 70%;
            margin: 0 auto;
            line-height: 2.5rem;
            letter-spacing: .1rem;
        }

        .iconos-redes{
            padding: 1.5rem 0 0 0;

            i{
                font-size: 3.5rem;
                margin: 0 .5rem;
            }

            .bxl-facebook{
                color: #255ac4;
            }
            
            .bxl-instagram{
                color: rgb(139, 23, 23);
            }

            .bxl-whatsapp{
                color: #219e31;
            }
        }
    }
}

@media (max-width:768px){
    .contactos{
        padding-top: 1.5rem;
    }
    .contactos .datos-contactos{
        padding: 2rem 6%;
        display: flex;
        flex-direction: column;
        text-align: center;
    
        .contacto{
            margin-top: 2rem;
        }
     }
}




</style>