// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index'
import { createMetaManager } from 'vue-meta';

import 'boxicons/css/boxicons.min.css';

import 'swiper/swiper-bundle.css';

// Importación de vue-lazyload
import VueLazyLoad from 'vue-lazyload';

createApp(App)
  .use(router)
  .use(store)
  .use(createMetaManager())
  .use(VueLazyLoad)
  .mount('#app');
