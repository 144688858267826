<template>
    <!-- inicio información -->
    <section class="informacion">
        <div class="container-informacion">
            <div class="informacion-item scroll-up">
                <i class='bx bxs-truck'></i>
                <h3>Envíos a todo el país</h3>
                <p>Envíos hasta la puerta de tu hogar, casco urbano de Managua por C$60.00. Envíos a Departamentos de Nicaragua a través de Cargo Trans.</p>
            </div>
            
            <div class="informacion-item scroll-up">
                <i class='bx bxs-bulb' ></i>
                <h3>Accesorios exclusivos</h3>
                <p>En Glamour encontrarás variedad de accesorios tanto únicos como personalizados para cada ocasión. Elaboramos, diseñamos y personalizamos el accesorio de tu preferencia. Haz tus consultas a nuestro WhatsApp. será un placer atenderte.</p>
            </div>

            <div class="informacion-item scroll-up">
                <i class='bx bxs-hand-down'></i>
                <h3>Hecho en Nicaragua</h3>
                <p>Nuestras manos artesanas se encargan de personalizar, crear y diseñar estilos que resalten tu belleza y que puedan ser usados en cualquier ocasión. Revisa nuestra página y haz tus consultas sobre los estilos disponibles.</p>
                <!-- <div id="sobre-nosotros"></div> -->
            </div>
        </div>
    </section>
    <!-- final información -->
</template>

<script>
</script>

<style lang="scss">
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";

    //informacion
    .informacion {
    padding: 0 6%;
    margin-top: 5rem;

    .container-informacion {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .informacion-item {
            border: 1px solid $dorado;
            width: calc(30% - 1rem);
            padding: 3rem 2rem;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            margin-bottom: 2rem;

            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            }

            .bx {
                color: $dorado;
                font-size: 4rem;
                margin-bottom: 1rem;
            }

            h3 {
                margin-top: 1rem;
                font-size: 2rem;
                color: $violeta;
                font-family: 'Lobster', cursive;
                font-weight: 500;
                letter-spacing: 1px;
            }

            p {
                width: 100%;
                margin-top: .6rem;
                font-size: 1.5rem;
                line-height: 1.6;
                // color: $violeta;
            }
        }
    }

    @media (max-width: 850px) {
        .container-informacion {
            flex-direction: column;
            align-items: center;

            .informacion-item {
                width: 100%;
                margin-bottom: 2rem;
                text-align: center;

                h3{
                    font-size: 2.5rem;
                }

                p{
                    font-size: 1.8rem;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .container-informacion {
            .informacion-item {
                width: calc(100% - 2rem);
            }
        }
    }
}


</style>