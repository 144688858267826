<template>
    <navCategoria/>
    <Enlaces/>
  
    <div class="productos-container">
      <!-- <h1>{{ titulo }}</h1> -->
      <div class="contenedor-titulos scroll-up">
              <img src="@/assets/img/fondo-titulos.png" alt="">
              <div class="texto"><h2>{{titulo}}</h2></div>
      </div>
      <div class="productos">
        <div class="producto" v-for="producto in productos" :key="producto.id">
          <div class="img-container">
            <img :src="producto.imagen" :alt="producto.nombre">
            <div class="icon-eye" @click="abrirModalInfo(producto)">
              <i class="bx bx-show"></i>
            </div>
          </div>
          <p class="ref">{{ producto.referencia }}</p>
          <p class="nombre-producto">{{ producto.nombre }}</p>
          <p class="precio">{{ producto.precio }}</p>
          <div class="cantidad">
            <button @click="decrementarCantidad(producto)">-</button>
            <span>{{ producto.cantidad }}</span>
            <button @click="incrementarCantidad(producto)">+</button>
          </div>
          <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
        </div>
      </div>
      
      <!-- Modal para agregar al carrito -->
      <div v-if="mostrarModalCarrito" class="modal2">
        <div class="modal-content2">
          <!-- <span class="close" @click="cerrarModalCarrito">&times;</span> -->
          <p class="modal-producto">Producto agregado al carrito</p>
        </div>
      </div>
      
      <!-- Modal para información del producto -->
      <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
        <div class="modal-content">
          <span class="close" @click="cerrarModalInfo">&times;</span>
          <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
          <p>Descripción</p>
          <li>{{ productoSeleccionado.descripcion }}</li>
        </div>
      </div>
    </div>
    <Footer/> 
  </template>
  
  
  
  
  <script>
  import NavCategoria from '@/components/navCategoria.vue'
  import Enlaces from '@/components/enlaces.vue'
  import Footer from '@/components/footer.vue'
  
  export default {
    components: {
      NavCategoria,
      Enlaces,
      Footer,
    },
    data() {
      return {
        titulo: "Anillos en acero inoxidable",
        productos: [
          {
            id: 1,
            referencia: "ref: GBR-RJEW-F080-05P",
            nombre: "Anillos de acero",
            precio: "C$250.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/1-GBR-RJEW-F080-05P-18mm. Anillos de acero inoxidable 304 con circonita cúbica, color plateado, tamaño 8, 18 mm. C$250.00.png'),
            descripcion: "Anillos de acero inoxidable 304 con circonita cúbica, color plateado, tamaño 8, 18 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 2,
            referencia: "ref: GBR-RJEW-I089-27G",
            nombre: "Anillos de acero",
            precio: "C$175.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/2-GBR-RJEW-I089-27G. Anillo de acero inoxidable 201 con banda simple y fina para mujer, dorado, diámetro interior 17 mm. C$175.00.png'),
            descripcion: " Anillo de acero inoxidable 201 con banda simple y fina para mujer, dorado, diámetro interior 17 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 3,
            referencia: "ref: GBR-RJEW-I089-27P",
            nombre: "Anillos de acero",
            precio: "C$175.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/3-GBR-RJEW-I089-27P. Anillo de acero inoxidable 201 con banda simple y fina para mujer, color plateado, diámetro interior 17 mm. C$175.00.png'),
            descripcion: "Anillo de acero inoxidable 201 con banda simple y fina para mujer, color plateado, diámetro interior 17 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 4,
            referencia: "ref: GBR-RJEW-N043-11E",
            nombre: "Anillo plano",
            precio: "C$245.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/4GBR-RJEW-N043-11E. Anillo plano con diamantes de imitación de cristal, joyería de acero inoxidable 201, electroforesis negra, diámetro interior 17 mm. C$245.00.png'),
            descripcion: "Anillo plano con diamantes de imitación de cristal, joyería de acero inoxidable 201, electroforesis negra, diámetro interior 17 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 5,
            referencia: "ref: GBR-RJEW-P036-01G-D",
            nombre: "Anillo de sello",
            precio: "C$210.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/5-GBR-RJEW-P036-01G-D. Anillo de sello de acero inoxidable 304, anillo redondo trenzado para mujer, patrón de corazón, tamaño de EE. UU. 7.14. C$210.00.png'),
            descripcion: "Anillo de sello de acero inoxidable 304, anillo redondo trenzado para mujer, patrón de corazón, tamaño de EE. UU. 7.14.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 6,
            referencia: "ref: GBR-RJEW-T005-13-10",
            nombre: "Anillos de banda",
            precio: "C$255.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/6-GBR-RJEW-T005-13-10. Anillos de banda ancha de acero de titanio, con acacia, tamaño 13, bronce de cañón, 22,3 mm. C$255.00.png'),
            descripcion: " Anillos de banda ancha de acero de titanio, con acacia, tamaño 13, bronce de cañón, 22,3 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 7,
            referencia: "ref: GBR-STAS-I160-D-17mm-B",
            nombre: "Anillos de banda",
            precio: "C$175.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/7-GBR-STAS-I160-D-17mm-B. Anillos de banda plana de acero inoxidable 304, color negro, tamaño 7, diámetro interior 17 mm, 3 mm. C$175.00.png'),
            descripcion: "Anillos de banda plana de acero inoxidable 304, color negro, tamaño 7, diámetro interior 17 mm, 3 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 8,
            referencia: "ref: GBR-STAS-I160-D-17mm-RG",
            nombre: "Anillos de banda",
            precio: "C$175.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/8-GBR-STAS-I160-D-17mm-RG. Anillos de banda plana de acero inoxidable 304, oro rosa, tamaño 7, diámetro interior 17 mm, 3 mm. C$175.00.png'),
            descripcion: "Anillos de banda plana de acero inoxidable 304, oro rosa, tamaño 7, diámetro interior 17 mm, 3 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 9,
            referencia: "ref: GBR-STAS-M309-01G",
            nombre: "Anillo de acero",
            precio: "C$255.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/9-GBR-STAS-M309-01G. Anillo de acero inoxidable 304, hueco, anillo de serpiente trenzado, dorado, 3 mm, diámetro interior 18 mm. C$255.00.png'),
            descripcion: "Anillo de acero inoxidable 304, hueco, anillo de serpiente trenzado, dorado, 3 mm, diámetro interior 18 mm.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
          {
            id: 10,
            referencia: "ref: GBR-VALE-PW0001-039C-04",
            nombre: "Anillo de acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/anillos/anillos_acero_inoxidable/10-GBR-VALE-PW0001-039C-04. Anillo de acero inoxidable con diseño de latidos del corazón, cambio de color, unisex, plateado. C$265.00.png'),
            descripcion: "Anillo de acero inoxidable con diseño de latidos del corazón, cambio de color, unisex, plateado.",
            seccion: "sección anillos acero inoxidable",
            cantidad: 1
          },
        ],
        mostrarModalCarrito: false,
        mostrarModalInfo: false,
        productoSeleccionado: null,
      }
    },
    methods: {
      incrementarCantidad(producto) {
        producto.cantidad++;
      },
      decrementarCantidad(producto) {
        if (producto.cantidad > 1) {
          producto.cantidad--;
        }
      },
      agregarAlCarrito(producto) {
        this.$store.commit('agregarAlCarrito', { ...producto });
        this.mostrarModalCarrito = true;
        setTimeout(() => {
          this.cerrarModalCarrito();
        }, 1000); // Modal se cierra automáticamente después de 1 segundo
      },
      abrirModalInfo(producto) {
        this.productoSeleccionado = producto;
        this.mostrarModalInfo = true;
      },
      cerrarModalCarrito() {
        this.mostrarModalCarrito = false;
      },
      cerrarModalInfo() {
        this.mostrarModalInfo = false;
        this.productoSeleccionado = null;
      }
    }
  }
  </script>
  
  
  
  <style lang="scss">
  @import "@/assets/styles/stylesProductos.scss";
  
  .contenedor-titulos{
          position: relative;
          display: inline-block;
          text-align: center;
          display: flex;
          justify-content: center;
  
          img{
              height: 8rem;
              width: 40rem;
          }
  
          .texto{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
  
              h2{
                  color: $violeta-titulos;
                  // font-family: 'Roboto', sans-serif;
                  font-size: 2.8rem;
                  // font-family: 'Crete Round', serif;
                  font-family: 'Lobster', cursive;
                  font-weight: 300;
                  letter-spacing: 1px;
              }
          }
  
      }
  
  .productos-container {
    padding: 10px;
  
    .productos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  
      .producto {
        margin: 20px;
  
        .img-container {
          position: relative;
  
          img {
            height: 29rem;
            width: 24rem;
            object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
          }
  
          .icon-eye {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
  
            i {
              color: #fff;
              font-size: 18px;
            }
          }
        }
  
        // .ref, .nombre-producto, .precio, .cantidad, button {
        //   text-align: center;
        //   font-size: 16px;
        // }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding-top: 130px;
  
      .modal-content {
        background-color: #fff;
        padding: 35px 13px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        max-width: 350px;
        width: 90%;
        // margin-top: 100px;
  
        p{
          color: $violeta;
          font-weight:700;
          font-size: 1.7rem;
          margin: -.5rem 0 .5rem 0;
        }
  
        li{
          font-size: 1.5rem;
        }
  
        img {
          width: 80%;
          height: 30rem;
          margin-bottom: 15px;
          object-fit: cover;
          // display: none;
        }
  
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    // .modal2{
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(0, 0, 0, 0.7);
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   z-index: 1000;
    //   padding-top: 130px;

    //   .modal-content2{
    //     background-color: #fff;
    //     padding: 35px 13px;
    //     border-radius: 8px;
    //     text-align: center;
    //     position: relative;
    //     max-width: 350px;
    //     width: 90%;
    //     // margin-top: 100px;
        
    //     p{
    //       color: $violeta;
    //       font-weight:700;
    //       font-size: 1.7rem;
    //       // margin: -.5rem 0 .5rem 0;
    //     }
    //   }
    // }
  
    @media (max-width: 768px) {
      .productos {
        flex-direction: column;
        align-items: center;
  
        .producto {
          width: 100%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              padding: 3px;
              i {
                font-size: 16px;
              }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal .modal-content {
        width: 95%;
        padding: 15px;
      }
    }
  
    @media (max-width: 700px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              // padding: 2px;
              // i {
              //   font-size: 16px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal{
        padding-top: 80px;
  
        .modal-content {
          width: 100%;
          padding: 10px;
  
        p{
          font-size: 2rem;
        }  
  
        li{
          margin: 1rem 0;
          font-size: 1.8rem;
        }
      }
      } 
    }
  
    @media (max-width: 450px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 5px 0;
  
          .img-container {
            .icon-eye {
              bottom: 3px;
              right: 3px;
              // padding: 2px;
              // i {
              //   font-size: 14px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 12px;
          }
        }
      }
    }
  
    @media (max-width: 400px){
      .modal .modal-content{
        width: 70%;

  
        img{
        width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
        height: 25rem;
        padding:2rem 0 .5rem 0;
      }
      } 
    }
  }
  </style>