<template>
    <!-- inicio productos recomendados -->
    <section class="titulos2">
        <!-- <div class="contenedor-titulos">
            <img src="img/titulos.png" alt="">
            <div class="texto"><h2>Productos Recomendados</h2></div>
        </div> -->

        <div class="swiper swiper2 mySwiper">
            <div class="contenedor-titulos scroll-up">
                <img src="../assets/img/fondo-titulos.png" alt="">
                <div class="texto"><h2>Productos Recomendados</h2></div>
            </div>
          <div class="swiper-wrapper">
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/conjuntos/17-GBR-KK-S263-263-NF. Conjunto de cadena en acero inoxidable, dije y aretes enchapados en oro, flor enchapada dorada. C$485.00.png')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/conjuntos"><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Colección conjuntos</h3>
                        <p>Conjunto de cadena en acero inoxidable</p>
                        <span>C$485.00</span>
                    </div>
                </div>
            </div>
    
    
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/aretes/29-GBR-EJEW-FI0002-29-RC. Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo. C$455.00 .png')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/aretes"><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Aretes colgantes</h3>
                        <p>cristal con cuentas en forma de corazón</p>
                        <span>C$455.00</span>
                    </div>
                </div>
            </div>
    
    
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/collares/collares_en_parejas_acero_inoxidable/1723044574853.png')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/collares/collares_en_parejas"><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Juego de dos collares</h3>
                        <p>Acero inoxidable</p>
                        <span>C$435.00</span>
                    </div>
                </div>
            </div>
    
    
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/pulseras/pulseras_en_platino/1-GBR-KK-S338-07-PCH. Pulsera estilo europeo con grabado pandora y 5 charms en acero inoxidable. C$995.00.png')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/charms/charms_pulseras_europeo"><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Pulsera estilo europeo</h3>
                        <p>Acero Inoxidable</p>
                        <span>C$995.00</span>
                    </div>
                </div>
            </div>
    
    
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/producto-recomendado-4.jpg')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/collares/collares_y_dijes"><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Colección collares</h3>
                        <p>Acero Inoxidable y zirconia</p>
                        <span>C$265</span>
                    </div>
                </div>
            </div>
    
    
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/producto-recomendado-5.jpg')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/charms/charms_perlas_colores "><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Colección charms</h3>
                        <p>Acero Inoxidable</p>
                        <span>C$75</span>
                    </div>
                </div>
            </div>
    
    
            <div class="swiper-slide">
                <div class="img__item">
                    <div class="gg">
                        <figure>
                            <img v-lazy="require('@/assets/img/producto-recomendado-6.jpg')" alt="accesorios exclusivos para ti glamour by ramos">
                        </figure>
                        <div class="container__item__hover">
                            <!-- <a href="https://www.instagram.com/glamourbyramos/" target="_blank"><h2>Ver Más</h2></a> -->
                            <router-link to="/collares/collares_y_dijes"><h2>Ver Más</h2></router-link>
                        </div> 
                    </div>
                    <div class="contenido-img-item">
                        <h3>Colección collares</h3>
                        <p>Acero Inoxidable</p>
                        <span>C$165</span>
                    </div>
                </div>
            </div>

          </div>
          <div class="swiper-pagination"></div>
          <div id="categoria"></div>
        </div>
        <!-- <div class="boton-comprar">
            <button><a href="catalogo.html" target="_blank">Comprar</a></button>
        </div> -->
    </section>
    <!-- final productos recomendados -->
</template>


<script>
  export default {
    mounted() {
      this.loadScript('https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.js')
        .then(() => {
          console.log('Swiper cargado desde CDN');

          // Lo más vendido
          var swiper2 = new Swiper(".mySwiper", {
            spaceBetween: 55,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            breakpoints: {
              320: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 40
              },
              1920: {
                slidesPerView: 5,
                spaceBetween: 50
              }
            }
          });

        })
        .catch((error) => {
          console.error('Error al cargar Swiper desde CDN:', error);
        });

      this.loadScript('https://unpkg.com/swiper@7/swiper-bundle.min.js')
        .then(() => {
          console.log('Swiper cargado desde unpkg');
          // Aquí puedes inicializar Swiper si es necesario
        })
        .catch((error) => {
          console.error('Error al cargar Swiper desde unpkg:', error);
        });
    },
    methods: {
      loadScript(src) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = src;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });
      }
    }
  }
</script>



<style lang="scss">
  @import "@/assets/styles/normalize.css";
  @import "@/assets/styles/variables.scss";
  @import "@/assets/styles/fonts.scss";

  //productos recomendados
  .titulos2 {
    padding-top: 0.8rem;
    // padding-bottom: 5rem;
    margin: 0 5rem;
    text-align: center;

    .contenedor-titulos {
      position: relative;
      display: inline-block;
      text-align: center;
      display: flex;
      justify-content: center;

      img {
        height: 8rem;
        width: 40rem;
      }

      .texto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        h2 {
          color: $violeta-titulos;
          font-size: 2.8rem;
          font-family: 'Lobster', cursive;
          font-weight: 300;
          letter-spacing: 1px;
        }
      }
    }
  }

  .swiper2 {
    padding: 0 3.5rem 5rem 3.5rem;

    .swiper-wrapper {
      margin-top: 2.2rem;
    }

    .img__item{
      margin-bottom: 5rem;
    }

    .swiper-wrapper .img__item .gg {
      position: relative;
      overflow: hidden;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    .img__item .contenido-img-item {
      margin-top: 2rem;
      text-align: center;

      h3 {
        font-size: 1.8rem;
        margin-bottom: .6rem;
        color: #333333;
      }

      p {
        margin-bottom: .6rem;
        font-size: 1.6rem;
        font-weight: 400;
        font-style: italic;
      }

      span {
        color: $violeta;
        font-size: 2rem;
        font-style: italic;
      }
    }

    .container__item__hover {
      padding: 0 6%;
      position: absolute;
      background: #1515158c;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8.5rem;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translateY(100%);
      cursor: pointer;
      transition: transform .3s ease-in-out;
      box-sizing: border-box;
    }

    .container__item__hover a {
      color: rgb(255, 255, 255);
      font-size: 1.3rem;
      text-decoration: none;
    }
  }

  html .img__item:hover .container__item__hover {
    transform: translateY(0);
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto; // Cambiado de altura fija a auto para mantener la relación de aspecto
    object-fit: cover;
    cursor: pointer;
    border-radius: .5rem;
  }

  span.swiper-pagination-bullet {
    // display: none;
    margin-top: 5rem;
    height: 2rem;
    width: 2rem;
    background: rgb(108, 108, 108);
    border-radius: 50%;
    border: 1px solid #ffffff;

    &.swiper-pagination-bullet-active {
      background: $violeta;
    }
  }
</style>
