<template>
    <navCategoria/>
    <Enlaces/>
  
    <div class="productos-container">
      <!-- <h1>{{ titulo }}</h1> -->
  
      <div class="contenedor-titulos scroll-up">
              <img src="@/assets/img/fondo-titulos.png" alt="">
              <div class="texto"><h2>{{titulo}}</h2></div>
      </div>
  
      <div class="productos">
        <div class="producto" v-for="producto in productos" :key="producto.id">
          <div class="img-container">
            <img :src="producto.imagen" :alt="producto.nombre">
            <div class="icon-eye" @click="abrirModalInfo(producto)">
              <i class="bx bx-show"></i>
            </div>
          </div>
          <p class="ref">{{ producto.referencia }}</p>
          <p class="nombre-producto">{{ producto.nombre }}</p>
          <p class="precio">{{ producto.precio }}</p>
          <div class="cantidad">
            <button @click="decrementarCantidad(producto)">-</button>
            <span>{{ producto.cantidad }}</span>
            <button @click="incrementarCantidad(producto)">+</button>
          </div>
          <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
        </div>
      </div>
      
      <!-- Modal para agregar al carrito -->
      <div v-if="mostrarModalCarrito" class="modal">
        <div class="modal-content">
          <!-- <span class="close" @click="cerrarModalCarrito">&times;</span> -->
          <p>Producto agregado al carrito</p>
        </div>
      </div>
      
      <!-- Modal para información del producto -->
      <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
        <div class="modal-content">
          <span class="close" @click="cerrarModalInfo">&times;</span>
          <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
          <p>Descripción</p>
          <li>{{ productoSeleccionado.descripcion }}</li>
        </div>
      </div>
    </div>
    <Footer/> 
  </template>
  
  
  
  
  <script>
  import NavCategoria from '@/components/navCategoria.vue'
  import Enlaces from '@/components/enlaces.vue'
  import Footer from '@/components/footer.vue'
  
  export default {
    components: {
      NavCategoria,
      Enlaces,
      Footer,
    },
    data() {
      return {
        titulo: "Colección conjuntos",
        productos: [
          {
            id: 1,
            referencia: "ref: GBR-CON-DOBLEAI-STRS-BLACK",
            nombre: "Conjunto de collar doble",
            precio: "C$355.00",
            imagen: require('@/assets/img/conjuntos/1-GBR-CON-DOBLEAI-STRS-BLACK. Conjunto de collar doble, aretes y pulsera de Acero Inoxidable con mostacillas de colores y estrellas. C$355.00.png'),
            descripcion: "Conjunto de collar doble, aretes y pulsera de Acero Inoxidable con mostacillas de colores y estrellas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 2,
            referencia: "ref: GBR-CON-DOBLEAI-STRS-PINK",
            nombre: "Conjunto de collar doble",
            precio: "C$355.00",
            imagen: require('@/assets/img/conjuntos/2-GBR-CON-DOBLEAI-STRS-PINK. Conjunto de collar doble, aretes y pulsera de Acero Inoxidable con mostacillas de colores y estrellas. C$355.00.png'),
            descripcion: "Conjunto de collar doble, aretes y pulsera de Acero Inoxidable con mostacillas de colores y estrellas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 3,
            referencia: "ref: GBR-CONJ-KK-I657-32G-B",
            nombre: "Conjunto de aretes",
            precio: "C$485.00",
            imagen: require('@/assets/img/conjuntos/3-GBR-CONJ-KK-I657-32G-B. Conjunto de aretes y collar en Acero Inoxidable con dije enchapado en Oro, Virgen de Guadalupe rodeada de perlas blancas. C$485.00.png'),
            descripcion: "Conjunto de aretes y collar en Acero Inoxidable con dije enchapado en Oro, Virgen de Guadalupe rodeada de perlas blancas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 4,
            referencia: "ref: GBR-CONJ-KK-N231-74-NF",
            nombre: "Conjunto de aretes",
            precio: "C$465.00",
            imagen: require('@/assets/img/conjuntos/4-GBR-CONJ-KK-N231-74-NF. Conjunto de aretes enchapados en oro, flor y collar de acero inoxidable con dije enchapado en oro real 18 quilates, flor, blanca y corona. C$465.00.png'),
            descripcion: "Conjunto de aretes enchapados en oro, flor y collar de acero inoxidable con dije enchapado en oro real 18 quilates, flor, blanca y corona.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 5,
            referencia: "ref: GBR-CONJ-KK-O142-56G-PK",
            nombre: "Conjunto de collar",
            precio: "C$375.00",
            imagen: require('@/assets/img/conjuntos/5-conjunto.png'),
            descripcion: "Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro de larga duración, dije de flor, rosa.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 6,
            referencia: "ref: GBR-CONJ-PEARLRIVER",
            nombre: "Conjunto de pulsera",
            precio: "C$445.00",
            imagen: require('@/assets/img/conjuntos/6-GBR-CONJ-PEARLRIVER. Conjunto de pulsera, aretes y collar en acero inoxidable con perlas de río narurales. C$445.00.png'),
            descripcion: "Conjunto de pulsera, aretes y collar en acero inoxidable con perlas de río narurales.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 7,
            referencia: "ref: GBR-CONJ-PEAR-R064-01",
            nombre: "Conjunto de Collar",
            precio: "C$325.00",
            imagen: require('@/assets/img/conjuntos/7-GBR-CONJ-PEAR-R064-01. Conjunto de Collar y areres dorados en Acero Inoxidable con perlas de río y estrella. C$325.00.png'),
            descripcion: "Conjunto de Collar y areres dorados en Acero Inoxidable con perlas de río y estrella.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 8,
            referencia: "ref: GBR-CONJ-PEARS-AI-L",
            nombre: "Conjunto de aretes",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/8-GBR-CONJ-PEARS-AI-L. Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada.",
            seccion: "sección aretes",
            cantidad: 1
          },
          {
            id: 9,
            referencia: "ref: GBR-CONJ-PEARS-AI-M",
            nombre: "Conjunto de aretes",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/9-GBR-CONJ-PEARS-AI-M. Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 10,
            referencia: "ref: GBR-CONJ-PEARS-AI-S",
            nombre: "Conjunto de aretes",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/10-GBR-CONJ-PEARS-AI-S. Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 11,
            referencia: "ref: GBR-CONJ-PINKPEARLS",
            nombre: "Conjunto de collar",
            precio: "C$455.00",
            imagen: require('@/assets/img/conjuntos/11-GBR-CONJ-PINKPEARLS. Conjunto de collar, aretes y pulsera en acero inoxidable con perlas naturales rosadas..png'),
            descripcion: "Conjunto de collar, aretes y pulsera en acero inoxidable con perlas naturales rosadas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 12,
            referencia: "ref: GBR-CONJ-STAS-L234-069G",
            nombre: "Conjunto de collar",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/12-GBR-CONJ-STAS-L234-069G. Conjunto de collar y aretes dorados en acero inoxidable, perlas y estrellas. C$265.00.png'),
            descripcion: "Conjunto de collar y aretes dorados en acero inoxidable, perlas y estrellas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 13,
            referencia: "ref: GBR-CONJ-STAS-L234-069G",
            nombre: "Conjunto de collar",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/13-GBR-CONJ-STAS-L234-069G. Conjunto de collar y aretes dorados en Acero Inoxidable, perlas y estrellas. C$265.00.png'),
            descripcion: "Conjunto de collar y aretes dorados en acero inoxidable, perlas y estrellas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 14,
            referencia: "ref: GBR-CONJ-ZIRC-G152-18B-G",
            nombre: "Conjunto de aretes",
            precio: "C$465.00",
            imagen: require('@/assets/img/conjuntos/14-GBR-CONJ-ZIRC-G152-18B-G.Conjunto de aretes enchapados en oro, formacruz minimalista y collar de acero inoxidable con dije enchapados en oro y circonitas, cruz, dorado. C$465.00.png'),
            descripcion: "Conjunto de aretes enchapados en oro, formacruz minimalista y collar de acero inoxidable con dije enchapados en oro y circonitas, cruz, dorado.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 15,
            referencia: "ref: GBR-DOBLE-WHITEPEARLS",
            nombre: "Conjunto de aretes",
            precio: "C$375.00",
            imagen: require('@/assets/img/conjuntos/15-GBR-DOBLE-WHITEPEARLS. Conjunto de aretes y cadena doble de perlas blancas y letra nácar personalizada, con detalles en acero inoxidable. C$375.00.png'),
            descripcion: "Conjunto de aretes y cadena doble de perlas blancas y letra nácar personalizada, con detalles en acero inoxidable.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 16,
            referencia: "ref: GBR-EJEW-G312-11G",
            nombre: "Conjunto de collar",
            precio: "C$485.00",
            imagen: require('@/assets/img/conjuntos/16-GBR-EJEW-G312-11G. Conjunto de collar en acero inoxidable, dije y aretes enchapados en oro. C$485.00.png'),
            descripcion: "Conjunto de collar en acero inoxidable, dije y aretes enchapados en oro.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 17,
            referencia: "ref: GBR-KK-S263-263-NF",
            nombre: "Conjunto de cadena",
            precio: "C$485.00",
            imagen: require('@/assets/img/conjuntos/17-GBR-KK-S263-263-NF. Conjunto de cadena en acero inoxidable, dije y aretes enchapados en oro, flor enchapada dorada. C$485.00.png'),
            descripcion: "Conjunto de cadena en acero inoxidable, dije y aretes enchapados en oro, flor enchapada dorada.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 18,
            referencia: "ref: GBR-LAMP-PH0002-05",
            nombre: "Conjunto de acero",
            precio: "C$435.00",
            imagen: require('@/assets/img/conjuntos/18-GBR-LAMP-PH0002-05. Conjunto de acero inoxidable aretes y collar doble con cristales, ojo turco y estrellas. C$435.00.png'),
            descripcion: "Conjunto de acero inoxidable aretes y collar doble con cristales, ojo turco y estrellas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 19,
            referencia: "ref: GBR-PEARS-AI-G",
            nombre: "Conjunto de acero",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/19-GBR-PEARS-AI-G. Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar en acero inoxidable con perlas de rio natural y letra acerada personalizada.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 20,
            referencia: "ref: GBR-WHITEGOLD-PEARLS",
            nombre: "Conjunto de aretes",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/20-GBR-WHITEGOLD-PEARLS. Conjunto de aretes y collar sencillo de perlas blancas y doradas, detalles en acero inoxidable. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar sencillo de perlas blancas y doradas, detalles en acero inoxidable.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 21,
            referencia: "ref: GBR-WHITE-PEARLS",
            nombre: "Conjunto de aretes",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/21-GBR-WHITE-PEARLS. Conjunto de aretes y collar sencillo de perlas blancas y detalles en acero inoxidable. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar sencillo de perlas blancas y detalles en acero inoxidable.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 22,
            referencia: "ref: GBR-WHITE-PEARLS",
            nombre: "Conjunto de aretes",
            precio: "C$265.00",
            imagen: require('@/assets/img/conjuntos/22-GBR-WHITE-PEARLS. Conjunto de aretes y collar sencillo de perlas blancas y detalles en acero inoxidable. C$265.00.png'),
            descripcion: "Conjunto de aretes y collar sencillo de perlas blancas y detalles en acero inoxidable.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 23,
            referencia: "ref: GBR-CON-DOBLEAI-STRS-WHITE",
            nombre: "Conjunto de collar",
            precio: "C$355.00",
            imagen: require('@/assets/img/conjuntos/23-GBR-CON-DOBLEAI-STRS-WHITE. Conjunto de collar doble, aretes y pulsera de Acero Inoxidable con mostacillas de colores y estrellas. C$355.00.png'),
            descripcion: "Conjunto de collar doble, aretes y pulsera de Acero Inoxidable con mostacillas de colores y estrellas.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 24,
            referencia: "ref: GBR-CONJ-KK-O142-56G-CL",
            nombre: "Conjunto de collar",
            precio: "C$375.00",
            imagen: require('@/assets/img/conjuntos/24-GBR-CONJ-KK-O142-56G-CL. Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro de larga duración, celeste. C$375.00.png'),
            descripcion: "Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro de larga duración, celeste.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 25,
            referencia: "ref: GBR-CONJ-KK-O142-56G-OR",
            nombre: "Conjunto de collar",
            precio: "C$375.00",
            imagen: require('@/assets/img/conjuntos/25-GBR-CONJ-KK-O142-56G-OR. Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro, dije de flor, naranja. C$375.00.png'),
            descripcion: "Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro, dije de flor, naranja.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
          {
            id: 26,
            referencia: "ref: GBR-CONJ-KK-O142-56G-YL",
            nombre: "Conjunto de collar",
            precio: "C$375.00",
            imagen: require('@/assets/img/conjuntos/26-GBR-CONJ-KK-O142-56G-YL. Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro, dije de flor, amarillo. C$375.00 (2).png'),
            descripcion: "Conjunto de collar de acero inoxidable, dije y aretes esmaltados con baño de oro real de 18 quilates, chapado en oro, dije de flor, amarillo.",
            seccion: "sección conjuntos",
            cantidad: 1
          },
        ],
        mostrarModalCarrito: false,
        mostrarModalInfo: false,
        productoSeleccionado: null,
      }
    },
    methods: {
      incrementarCantidad(producto) {
        producto.cantidad++;
      },
      decrementarCantidad(producto) {
        if (producto.cantidad > 1) {
          producto.cantidad--;
        }
      },
      agregarAlCarrito(producto) {
        this.$store.commit('agregarAlCarrito', { ...producto });
        this.mostrarModalCarrito = true;
        setTimeout(() => {
          this.cerrarModalCarrito();
        }, 1000); // Modal se cierra automáticamente después de 1 segundo
      },
      abrirModalInfo(producto) {
        this.productoSeleccionado = producto;
        this.mostrarModalInfo = true;
      },
      cerrarModalCarrito() {
        this.mostrarModalCarrito = false;
      },
      cerrarModalInfo() {
        this.mostrarModalInfo = false;
        this.productoSeleccionado = null;
      }
    }
  }
  </script>
  
  
  
  <style lang="scss">
  @import "@/assets/styles/stylesProductos.scss";
  
  .contenedor-titulos{
          position: relative;
          display: inline-block;
          text-align: center;
          display: flex;
          justify-content: center;
  
          img{
              height: 8rem;
              width: 40rem;
          }
  
          .texto{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
  
              h2{
                  color: $violeta-titulos;
                  // font-family: 'Roboto', sans-serif;
                  font-size: 2.8rem;
                  // font-family: 'Crete Round', serif;
                  font-family: 'Lobster', cursive;
                  font-weight: 300;
                  letter-spacing: 1px;
              }
          }
  
      }
  
  .productos-container {
    padding: 10px;
  
    .productos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  
      .producto {
        margin: 20px;
  
        .img-container {
          position: relative;
  
          img {
            height: 29rem;
            width: 24rem;
            object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
          }
  
          .icon-eye {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
  
            i {
              color: #fff;
              font-size: 18px;
            }
          }
        }
  
        // .ref, .nombre-producto, .precio, .cantidad, button {
        //   text-align: center;
        //   font-size: 16px;
        // }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding-top: 130px;
  
      .modal-content {
        background-color: #fff;
        padding: 35px 13px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        max-width: 350px;
        width: 90%;
        // margin-top: 100px;
  
        p{
          color: $violeta;
          font-weight:700;
          font-size: 1.7rem;
          margin: -.5rem 0 .5rem 0;
        }
  
        li{
          font-size: 1.5rem;
        }
  
        img {
          width: 80%;
          height: 30rem;
          margin-bottom: 15px;
          object-fit: cover;
          // display: none;
        }
  
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  
    @media (max-width: 768px) {
      .productos {
        flex-direction: column;
        align-items: center;
  
        .producto {
          width: 100%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              padding: 3px;
              i {
                font-size: 16px;
              }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal .modal-content {
        width: 95%;
        padding: 15px;
      }
    }
  
    @media (max-width: 700px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              // padding: 2px;
              // i {
              //   font-size: 16px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal{
        padding-top: 80px;
  
        .modal-content {
          width: 100%;
          padding: 10px;
  
        p{
          font-size: 2rem;
        }  
  
        li{
          margin: 1rem 0;
          font-size: 1.8rem;
        }
      }
      } 
    }
  
    @media (max-width: 450px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 5px 0;
  
          .img-container {
            .icon-eye {
              bottom: 3px;
              right: 3px;
              // padding: 2px;
              // i {
              //   font-size: 14px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 12px;
          }
        }
      }
    }
  
    @media (max-width: 400px){
      .modal .modal-content{
        width: 70%;
  
        img{
        width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
        height: 25rem;
        padding:2rem 0 .5rem 0;
      }
      } 
    }
  }
  </style>
  
  
  
  
  
  