<template>
    <!-- inicio sobre Nosotros -->
    <section class="titulos">
        <div id="sobre-nosotros"></div>
        <div class="contenedor-titulos scroll-up">
            <img src="../assets/img/fondo-titulos.png" alt="">
            <div class="texto"><h2>Sobre Nosotros</h2></div>
        </div>

        <div class="container-nosotros scroll-up">
            <div class="textos-nosotros">
                <figure>
                    <img v-lazy="require('@/assets/img/logo-sobre.png')" alt="logo gabriela by ramos">
                </figure>
    
                <p>Glamour by Ramos, nació en Managua en el año 2017, como una idea de emprendimiento que resalte la belleza femenina de todas nuestras bellas nicaragüenses, desde entonces, Glamour se ha encargado de crear, diseñar y personalizar accesorios en diversidad de materiales y diseños. Todo esto con el fin de brindarle a nuestras bellas ciudadanas, su mejor opción para elección de accesorios.</p>
            </div>
    
            <div class="imagen-nosotros">
                <figure>
                    <img v-lazy="require('@/assets/img/sobre-nosotros.jpg')" alt="">
                </figure>
            </div>
        </div>
        <div class="textico">
            <p>
                Nuevos estilos cada semana,  síguenos en nuestras redes sociales y entérate de todas las novedades que Glamour tiene para ti.
            </p>
        </div>
    </section>
    <!-- final sobre Nosotros -->
</template>

<script>
</script>

<style lang="scss">
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";

    //sobre nosotros
    .titulos{
    padding: 2rem 0;
    // width: 100%;
    // height: 100%;

    .contenedor-titulos{
        position: relative;
        display: inline-block;
        text-align: center;
        display: flex;
        justify-content: center;

        img{
            height: 8rem;
            width: 40rem;
        }

        .texto{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            h2{
                color: $violeta-titulos;
                // font-family: 'Roboto', sans-serif;
                font-size: 2.8rem;
                // font-family: 'Crete Round', serif;
                font-family: 'Lobster', cursive;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }

    }

    .textico{
        padding: 1rem 6%;

        p{  
            text-align: center;
            font-size: 2.1rem;
            font-weight: 400;
            line-height: 3rem;
            background: #F6F3EF;
            padding: 2rem;
            color: $dorado;
            // font-style: italic;
            font-family: 'Lobster', cursive;
            letter-spacing: 1px;
        }
    }

    .container-nosotros{
        padding: 0 6%;
        margin-top: 2.5rem;
        display: flex;
        justify-content: space-between;

        img{
            height: 15rem;
            width: 100%;
            object-fit:cover ;
        }

        .textos-nosotros{
            width: 50%;
            background: #F6F3EF;
            text-align: center;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            // height: 100%;

            p{
                width: 80%;
                font-size: 2rem;
                font-weight: 300;
                line-height: 3rem;
            }
        }

        .imagen-nosotros{
            width: 50%;

            img{
                height: 60rem;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (max-width: 700px) {
    .titulos .container-nosotros{
        display: flex;
        flex-direction: column-reverse;

        .textos-nosotros{
            width: 100%;

            p{
                width: 70%;
                padding-bottom: 2rem;
            }
        }

        .imagen-nosotros{
            width: 100%;
        }
      }
    }

   
    @media (max-width: 550px){
        .titulos .container-nosotros .textos-nosotros img{
        margin-top: 1rem;
        height: 10rem;
        width: 27rem;
      }

      .titulos .container-nosotros .textos-nosotros p{
        font-size: 1.9rem;
      }
    } 

    @media (max-width: 450px){
        .titulos .container-nosotros .textos-nosotros p{
        width: 90%;
    }

    .titulos .container-nosotros .imagen-nosotros img{
        height: 35rem;
     }
    } 
</style>