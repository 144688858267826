<template>

    <Nav/>
    <Slider/>
    <Informacion/>
    <SobreNosotros/>
    <ProductosRecomendados/> 
    <!-- <Categorias/>  -->
    <Instagram/> 
    <Formulario/> 
    <Contactos/> 
    <Footer/> 
    
  </template>
  
  <script>
  import Nav from '../components/nav.vue'
  import Slider from '../components/slider.vue'
  import Informacion from '../components/informacion.vue'
  import SobreNosotros from '../components/sobreNosotros.vue'
  import ProductosRecomendados from '../components/productosRecomendados.vue'
  import Formulario from '../components/formulario.vue'
  // import Categorias from '../components/IrAcategorias.vue'
  import Instagram from '../components/instagram.vue'
  import Footer from '../components/footer.vue'
  import Contactos from '../components/contactos.vue'
  
  
  
  export default {
    name: 'App',
    components: {
      Nav,
      Slider,
      Informacion,
      SobreNosotros,
      ProductosRecomendados,
      Formulario,
      // Categorias,
      Instagram,
      Contactos,
      Footer,
    }
  }
  </script>
  
  <style>
  
  </style>
  