<template>
  <navCategoria/>
  <Enlaces/>

  <div class="productos-container">
    <!-- <h1>{{ titulo }}</h1> -->
    <div class="contenedor-titulos scroll-up">
            <img src="@/assets/img/fondo-titulos.png" alt="">
            <div class="texto"><h2>{{titulo}}</h2></div>
    </div>
    <div class="productos">
      <div class="producto" v-for="producto in productos" :key="producto.id">
        <div class="img-container">
          <img :src="producto.imagen" :alt="producto.nombre">
          <div class="icon-eye" @click="abrirModalInfo(producto)">
            <i class="bx bx-show"></i>
          </div>
        </div>
        <p class="ref">{{ producto.referencia }}</p>
        <p class="nombre-producto">{{ producto.nombre }}</p>
        <p class="precio">{{ producto.precio }}</p>
        <div class="cantidad">
          <button @click="decrementarCantidad(producto)">-</button>
          <span>{{ producto.cantidad }}</span>
          <button @click="incrementarCantidad(producto)">+</button>
        </div>
        <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
      </div>
    </div>
    
    <!-- Modal para agregar al carrito -->
    <div v-if="mostrarModalCarrito" class="modal">
      <div class="modal-content">
        <!-- <span class="close" @click="cerrarModalCarrito">&times;</span> -->
        <p>Producto agregado al carrito</p>
      </div>
    </div>
    
    <!-- Modal para información del producto -->
    <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
      <div class="modal-content">
        <span class="close" @click="cerrarModalInfo">&times;</span>
        <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
        <p>Descripción</p>
        <li>{{ productoSeleccionado.descripcion }}</li>
      </div>
    </div>
  </div>
  <Footer/> 
</template>




<script>
import NavCategoria from '@/components/navCategoria.vue'
import Enlaces from '@/components/enlaces.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    NavCategoria,
    Enlaces,
    Footer,
  },
  data() {
    return {
      titulo: "Pulseras acero inoxidable",
      productos: [
        {
          id: 1,
          referencia: "ref: GBR-BJEW-BB12573",
          nombre: "Pulsera Unisex plateada",
          precio: "C$500.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/1-GBR-BJEW-BB12573. Pulsera Unisex plateada, grabado 925. C$500.00.png'),
          descripcion: "Pulsera Unisex plateada, grabado 925.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 2,
          referencia: "ref: GBR-BJEW-G525-24P",
          nombre: "Pulseras de acero",
          precio: "C$255.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/2-GBR-BJEW-G525-24P. Pulseras de acero inoxidable 304 con dijes, plateado, con imitación de diamantes en vidrio. C$255.00.png'),
          descripcion: "Pulseras de acero inoxidable 304 con dijes, plateado, con imitación de diamantes en vidrio.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 3,
          referencia: "ref: GBR-BJEW-G669-14G",
          nombre: "Pulsera de malla",
          precio: "C$485.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/3-GBR-BJEW-G669-14G. Pulsera de malla de acero inoxidable 304, estilo correa de reloj para hombres y mujeres, dorada, 17 cm. C$485.00.png'),
          descripcion: "Pulsera de malla de acero inoxidable 304, estilo correa de reloj para hombres y mujeres, dorada, 17 cm.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 4,
          referencia: "ref: GBR-BJEW-H511-01",
          nombre: "Pulsera en Acero",
          precio: "C$195.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/4-GBR-BJEW-H511-01. Pulsera en Acero Inoxidable con ojos turcos de Colores. C$195.00.png'),
          descripcion: "Pulsera en Acero Inoxidable con ojos turcos de Colores.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 5,
          referencia: "ref: GBR-BJEW-I283-08",
          nombre: "Conjuntos de pulseras",
          precio: "C$425.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/5-GBR-BJEW-I283-08. Conjuntos de pulseras para parejas de acero inoxidable 304, rectángulo con corazón dividido, color dorado y plateado. C$425.00 .png'),
          descripcion: "Conjuntos de pulseras para parejas de acero inoxidable 304, rectángulo con corazón dividido, color dorado y plateado.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 8,
          referencia: "ref: GBR-BJEW-M121-03G",
          nombre: "Pulsera en Acero",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/8-GBR-BJEW-M121-03G. Pulsera en Acero Inoxidable dorado, corazónes minimalistas. C$185.00.png'),
          descripcion: "Pulsera en Acero Inoxidable dorado, corazónes minimalistas.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 9,
          referencia: "ref: GBR-CHS-WH0001-06-WHITEPEARL",
          nombre: "Pulsera en Acero",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/9-GBR-CHS-WH0001-06-WHITEPEARL. Pulsera de Acero Inoxidable, eslabones grandes dorados y perla de rio. C$185.00.png'),
          descripcion: "Pulsera de Acero Inoxidable, eslabones grandes dorados y perla de rio.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 10,
          referencia: "ref: GBR-CRISTALPEARLS-BEIGE",
          nombre: "Pulsera elastica",
          precio: "C$235.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/10-GBR-CRISTALPEARLS-BEIGE. Pulsera elastica con perlas de cristal y perlitas doradas de Acero Inoxidable. C$235.00.png'),
          descripcion: "Pulsera elastica con perlas de cristal y perlitas doradas de Acero Inoxidable.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 11,
          referencia: "ref: GBR-EGLA-A034-P4mm-WHITEPEARL",
          nombre: "Pulsera elastica",
          precio: "C$205.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/11-GBR-EGLA-A034-P4mm-WHITEPEARL. Pulsera elastica de cristales crema y perlas naturales blancas. C$205.00.png'),
          descripcion: "Pulsera elastica de cristales crema y perlas naturales blancas.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 12,
          referencia: "ref: GBR-LAMP-PH0002-05-RED",
          nombre: "Pulsera en Acero",
          precio: "C$165.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/12-GBR-LAMP-PH0002-05-RED. Pulsera en Acero Inoxidable con cristales y ojo turco, Rojo. C$165.00.png'),
          descripcion: "Pulsera en Acero Inoxidable con cristales y ojo turco, Rojo.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 13,
          referencia: "ref: GBR-PEARL-ACEI-CUAR-AMA",
          nombre: "Pulsera en Acero",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/13-GBR-PEARL-ACEI-CUAR-AMA. Pulsera en Acero Inoxidable, cuarzo morado, amatista, perlas doradas de Acero Inoxidable y perlas blancas porcelana. C$185.00.png'),
          descripcion: "Pulsera en Acero Inoxidable, cuarzo morado, amatista, perlas doradas de Acero Inoxidable y perlas blancas porcelana.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 14,
          referencia: "ref: GBR-PEARL-ACEI-CUAR-GREEN",
          nombre: "Pulsera en Acero",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/14-GBR-PEARL-ACEI-CUAR-GREEN. Pulsera en Acero Inoxidable, cuarzo Verde, perlas doradas de Acero Inoxidable y perlas blancas porcelana. C$185.00.png'),
          descripcion: "Pulsera en Acero Inoxidable, cuarzo Verde, perlas doradas de Acero Inoxidable y perlas blancas porcelana.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 15,
          referencia: "ref: GBR-PEARL-ACEI-CUAR-MIX",
          nombre: "Pulsera en Acero",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/15-GBR-PEARL-ACEI-CUAR-MIX. Pulsera en Acero Inoxidable, cuarzos rosa, verde y lila, perlas doradas de Acero Inoxidable y perlas blancas porcelana. C$185.00.png'),
          descripcion: "Pulsera en Acero Inoxidable, cuarzos rosa, verde y lila, perlas doradas de Acero Inoxidable y perlas blancas porcelana.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 16,
          referencia: "ref: GBR-PEARL-ACEI-CUAR-PINK",
          nombre: "Pulsera en Acero",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/16-GBR-PEARL-ACEI-CUAR-PINK. Pulsera en Acero Inoxidable, cuarzo rosa, perlas doradas de Acero Inoxidable y perlas blancas porcelana. C$185.00.png'),
          descripcion: "Pulsera en Acero Inoxidable, cuarzo rosa, perlas doradas de Acero Inoxidable y perlas blancas porcelana.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 17,
          referencia: "ref: GBR-REDC-2mm-Q201-H557-L244",
          nombre: "Pulsera elastica",
          precio: "C$155.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/17-GBR-REDC-2mm-Q201-H557-L244. Pulsera elastica en cristales rojos y dijes de estrella, media luna y delfin en Acero Inoxidable. C$155.00.png'),
          descripcion: "Pulsera elastica en cristales rojos y dijes de estrella, media luna y delfin en Acero Inoxidable.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 18,
          referencia: "ref: GBR-STAS-B020-12G",
          nombre: "Pulsera en Acero",
          precio: "C$225.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/18-GBR-STAS-B020-12G. Pulsera en Acero Inoxidable con lámina de Acero Inoxidable dorada. C$225.00.png'),
          descripcion: "Pulsera en Acero Inoxidable con lámina de Acero Inoxidable dorada.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 19,
          referencia: "ref: GBR-STAS-B020-12P",
          nombre: "Pulsera en Acero",
          precio: "C$265.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/19-GBR-STAS-B020-12P. Pulsera de Acero Inoxidable con lámina de Acero Inoxidable plateada y cordón ajustable. C$265.00.png'),
          descripcion: "Pulsera de Acero Inoxidable con lámina de Acero Inoxidable plateada y cordón ajustable.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 20,
          referencia: "ref: GBR-STAS-Q201-T461G",
          nombre: "Pulsera en Acero",
          precio: "C$175.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/20-GBR-STAS-Q201-T461G. Pulsera en acero inoxidable con cadenas colgante, estrellas y mostaciilas. C$175.00.png'),
          descripcion: "Pulsera en acero inoxidable con cadenas colgante, estrellas y mostaciilas.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 21,
          referencia: "ref: GBR-STAS-Q201-T463S-RED",
          nombre: "Pulsera elastica",
          precio: "C$185.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/21-GBR-STAS-Q201-T463S-RED. Pulsera elastica, cristales rojos y transparentes con dije de elefante plateado. C$185.00.png'),
          descripcion: "Pulsera elastica, cristales rojos y transparentes con dije de elefante plateado.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 22,
          referencia: "ref: GBR-STAS-S115-01C-G",
          nombre: "Pulsera tejida",
          precio: "C$355.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/22-GBR-STAS-S115-01C-G. Pulsera tejida en telar con mostracillas de colores y terminales en Acero Inoxidable. C$355.00.png'),
          descripcion: "Pulsera tejida en telar con mostracillas de colores y terminales en Acero Inoxidable.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 23,
          referencia: "ref: GBR-STAS-S115-01E-G",
          nombre: "Pulsera tejida",
          precio: "C$355.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/23-GBR-STAS-S115-01E-G. Pulsera tejida en telar con mostracillas de colores y terminales en Acero Inoxidable. C$355.00.png'),
          descripcion: "Pulsera tejida en telar con mostracillas de colores y terminales en Acero Inoxidable.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 24,
          referencia: "ref: GBR-WHITE-CRISTAL-COLORMIX.",
          nombre: "Pulsera elastica",
          precio: "C$155.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/24-GBR-WHITE-CRISTAL-COLORMIX. Pulsera elastica en cristales blancos y cuencas porcelana de colores. C$155.00.png'),
          descripcion: "Pulsera elastica en cristales blancos y cuencas porcelana de colores.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 25,
          referencia: "ref: GBR-X-BJEW-JB08543",
          nombre: "Pulsera de eslabones",
          precio: "C$465.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/25-GBR-X-BJEW-JB08543. Pulsera de eslabones cruzados de acero inoxidable 201 con cadenas de perlas naturales para mujer, dorada, 18cm. C$465.00.png'),
          descripcion: "Pulsera de eslabones cruzados de acero inoxidable 201 con cadenas de perlas naturales para mujer, dorada, 18cm.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
        {
          id: 26,
          referencia: "ref: GBR-X-STAS-H436-CRISTALWHITE",
          nombre: "Pulsera elástica",
          precio: "C$165.00",
          imagen: require('@/assets/img/pulseras/pulseras_acero_inoxidable/26-GBR-X-STAS-H436-CRISTALWHITE. Pulsera elástica, con cruz de acero Inoxidable y cristales de colores. C$165.00.png'),
          descripcion: "Pulsera elástica, con cruz de acero Inoxidable y cristales de colores.",
          seccion: "sección pulseras acero inoxidable",
          cantidad: 1
        },
      ],
      mostrarModalCarrito: false,
      mostrarModalInfo: false,
      productoSeleccionado: null,
    }
  },
  methods: {
    incrementarCantidad(producto) {
      producto.cantidad++;
    },
    decrementarCantidad(producto) {
      if (producto.cantidad > 1) {
        producto.cantidad--;
      }
    },
    agregarAlCarrito(producto) {
      this.$store.commit('agregarAlCarrito', { ...producto });
      this.mostrarModalCarrito = true;
      setTimeout(() => {
        this.cerrarModalCarrito();
      }, 1000); // Modal se cierra automáticamente después de 1 segundo
    },
    abrirModalInfo(producto) {
      this.productoSeleccionado = producto;
      this.mostrarModalInfo = true;
    },
    cerrarModalCarrito() {
      this.mostrarModalCarrito = false;
    },
    cerrarModalInfo() {
      this.mostrarModalInfo = false;
      this.productoSeleccionado = null;
    }
  }
}
</script>



<style lang="scss">
@import "@/assets/styles/stylesProductos.scss";

.contenedor-titulos{
        position: relative;
        display: inline-block;
        text-align: center;
        display: flex;
        justify-content: center;

        img{
            height: 8rem;
            width: 40rem;
        }

        .texto{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            h2{
                color: $violeta-titulos;
                // font-family: 'Roboto', sans-serif;
                font-size: 2.8rem;
                // font-family: 'Crete Round', serif;
                font-family: 'Lobster', cursive;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }

    }

.productos-container {
  padding: 10px;

  .productos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .producto {
      margin: 20px;

      .img-container {
        position: relative;

        img {
          height: 29rem;
          width: 24rem;
          object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
        }

        .icon-eye {
          position: absolute;
          bottom: 10px;
          right: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 5px;
          border-radius: 50%;
          cursor: pointer;

          i {
            color: #fff;
            font-size: 18px;
          }
        }
      }

      // .ref, .nombre-producto, .precio, .cantidad, button {
      //   text-align: center;
      //   font-size: 16px;
      // }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding-top: 130px;

    .modal-content {
      background-color: #fff;
      padding: 35px 13px;
      border-radius: 8px;
      text-align: center;
      position: relative;
      max-width: 350px;
      width: 90%;
      // margin-top: 100px;

      p{
        color: $violeta;
        font-weight:700;
        font-size: 1.7rem;
        margin: -.5rem 0 .5rem 0;
      }

      li{
        font-size: 1.5rem;
      }

      img {
        width: 80%;
        height: 30rem;
        margin-bottom: 15px;
        object-fit: cover;
        // display: none;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    .productos {
      flex-direction: column;
      align-items: center;

      .producto {
        width: 100%;
        margin: 10px 0;

        .img-container {
          img {
            width: 100%;  // Ajusta el ancho de la imagen según el contenedor
            height: auto; // Mantén la proporción de la imagen
          }
          .icon-eye {
            bottom: 5px;
            right: 5px;
            padding: 3px;
            i {
              font-size: 16px;
            }
          }
        }

        .ref, .nombre-producto, .precio, .cantidad, button {
          font-size: 14px;
        }
      }
    }

    .modal .modal-content {
      width: 95%;
      padding: 15px;
    }
  }

  @media (max-width: 700px) {
    .productos {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .producto {
        width: 48%;
        margin: 10px 0;

        .img-container {
          img {
            width: 100%;  // Ajusta el ancho de la imagen según el contenedor
            height: auto; // Mantén la proporción de la imagen
          }
          .icon-eye {
            bottom: 5px;
            right: 5px;
            // padding: 2px;
            // i {
            //   font-size: 16px;
            // }
          }
        }

        .ref, .nombre-producto, .precio, .cantidad, button {
          font-size: 14px;
        }
      }
    }

    .modal{
      padding-top: 80px;

      .modal-content {
        width: 100%;
        padding: 10px;

      p{
        font-size: 2rem;
      }  

      li{
        margin: 1rem 0;
        font-size: 1.8rem;
      }
    }
    } 
  }

  @media (max-width: 450px) {
    .productos {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .producto {
        width: 48%;
        margin: 5px 0;

        .img-container {
          .icon-eye {
            bottom: 3px;
            right: 3px;
            // padding: 2px;
            // i {
            //   font-size: 14px;
            // }
          }
        }

        .ref, .nombre-producto, .precio, .cantidad, button {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 400px){
    .modal .modal-content{
      width: 70%;

      img{
      width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
      height: 25rem;
      padding:2rem 0 .5rem 0;
    }
    } 
  }
}
</style>





