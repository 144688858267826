<template>
    <footer class="footer">
      <div class="container-footer scroll-up">
        <div class="logo">
                <h3>Glamour By Ramos</h3>
                <hr>
                <p>Accesorios hechos a mano con mucho amor.
                elegancia y estilo solo para ti.</p>
        </div>

        <div class="links">
                <h3>Enlaces</h3>
                <hr>
                <a href="#">Inicio</a>
                <a href="#categoria">Comprar Ahora</a>
                <a href="#sobre-nosotros">Quíenes Somos</a>
        </div>

        <div class="horarios">
                <h3>Horarios</h3>
                <hr>
                <span>Lunes - Sábado</span>
                <span>9:00am - 7:00pm</span>
        </div>

        <div class="contactanos">
                <h3>Contactos</h3>
                <hr>
                <a href="https://api.whatsapp.com/send/?phone=%2B50587617146" target="_blank">WhatsApp</a>
                <a href="https://www.facebook.com/GlamourbyRamos17/" target="_blank">Facebook</a>
                <a href="https://www.instagram.com/glamourbyramos/" target="_blank">Instagram</a>
                <a href="https://pin.it/5twLola" target="_blank">Pinterest</a>
        </div>

        <div class="suscripcion">
           <h3>Suscríbete</h3>
          <hr>
            <span>Regístrate y recibe las últimas <br> promociones</span>
              <form class="container-sus" @submit.prevent="enviarSuscripcion">
                 <input type="email" v-model="email" placeholder="Introduce tu correo" required />
                 <button type="submit">Suscríbete</button>
              </form>
        </div>
      </div>
    </footer>
    <div class="copy">
      <span>Created By <span class="span">Andrés Rivera</span> | All Rights Reserved!</span>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

export default {
  data() {
    return {
      email: ''
    };
  },
  methods: {
    async enviarSuscripcion() {
      try {
        const response = await axios.post('/scripts/enviar-suscripcion.php', {
          email: this.email
        });

        if (response.status === 200) {
          alert('Suscripción exitosa');
          this.email = ''; // Limpiar el campo de email
        } else {
          alert('Hubo un problema con la suscripción');
        }
      } catch (error) {
        console.error('Error:', error.response.data);
        alert('Hubo un error al enviar la suscripción');
      }
    }
  }
};

  </script>
  
  
  
  <style lang="scss">
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";
  
    //footer
    .footer {
      padding: 5rem 6% 0 6%;
      width: 100%;
      background: #F8F2FC;
      border-top: 1px solid $dorado;
  
      .container-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 3rem 0 0 0;
  
        hr {
          border: 1px solid $violeta;
          width: 100%;
          margin: .5rem 0 1rem;
        }
  
        .logo {
          width: 18%;
          margin-top: 0.2rem;
          margin-bottom: 2.5rem;
  
          h3 {
            font-size: 2.5rem;
            font-family: 'Lobster', cursive;
            color: $violeta;
            font-weight: 300;
          }
  
          p {
            font-size: 1.5rem;
            color: #333333;
            line-height: 2.5rem;
          }
        }
  
        .links {
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          margin-bottom: 2.5rem;
  
          h3 {
            font-size: 2.5rem;
            font-family: 'Lobster', cursive;
            color: $violeta;
            font-weight: 300;
          }
  
          a {
            color: #333333;
            margin-bottom: 1.5rem;
            text-decoration: none;
  
            &:hover {
              color: $violeta;
            }
          }
        }
  
        .horarios {
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          margin-bottom: 2.5rem;
  
          h3 {
            font-size: 2.5rem;
            font-family: 'Lobster', cursive;
            color: $violeta;
            font-weight: 300;
          }
  
          span {
            color: #333333;
            margin-bottom: 1.5rem;
          }
        }
  
        .contactanos {
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          margin-bottom: 2.5rem;
  
          h3 {
            font-size: 2.5rem;
            font-family: 'Lobster', cursive;
            color: $violeta;
            font-weight: 300;
          }
  
          a {
            color: #333333;
            margin-bottom: 1.5rem;
            text-decoration: none;
  
            &:hover {
              color: $violeta;
            }
          }
        }
  
        .suscripcion {
          h3 {
            font-size: 2.5rem;
            font-family: 'Lobster', cursive;
            color: $violeta;
            font-weight: 300;
          }
  
          span {
            font-size: 1.4rem;
            color: #333333;
          }
  
          .container-sus {
            display: flex;
            flex-direction: column;
  
            input {
              padding: .8rem .5rem;
              font-size: 1.2rem;
              margin: 1rem 0;
              width: 70%;
              border: 1px solid #ddd;
            }
  
            button {
              width: 50%;
              border: none;
              border-radius: 5px;
              color: white;
              background: linear-gradient(to right, #9a258e, #74167b);
              font-size: 12px;
              padding: 1rem 2rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  
    //copy
    body .copy {
      padding: 2rem 0;
      text-align: center;
      background: #f2eff5;
      width: 100%;
  
      span {
        font-size: 1.7rem;
  
        .span {
          color: $violeta;
        }
      }
    }
  
    @media (max-width: 700px) {
      footer .suscripcion .container-sus input {
        width: 20%;
      }
  
      .footer .container-footer {
        flex-direction: column;
  
        hr {
          width: 60%;
          margin-bottom: 1.5rem;
        }
  
        .logo h3 {
          font-size: 2.8rem;
        }
  
        .logo p {
          font-size: 1.8rem;
        }
  
        .links h3 {
          font-size: 2.8rem;
        }
  
        .links a {
          font-size: 1.8rem;
        }
  
        .horarios h3 {
          font-size: 2.8rem;
        }
  
        .horarios span {
          font-size: 1.8rem;
        }
  
        .contactanos h3 {
          font-size: 2.8rem;
        }
  
        .contactanos a {
          font-size: 1.8rem;
        }
      }
  
      .footer .container-footer .logo {
        width: 100%;
  
        p {
          width: 70%;
        }
      }
    }

    @media (max-width: 480px){
      .footer .container-footer .suscripcion span{
        font-size: 1.7rem;
        letter-spacing: 1px;
        line-height: 17px
      }

      .footer .suscripcion .container-sus input{
        height: 5rem;
      }
    }
  </style>
  