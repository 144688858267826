import { createStore } from 'vuex';

const store = createStore({
  state: {
    carrito: []
  },
  mutations: {
    agregarAlCarrito(state, producto) {
      state.carrito.push(producto);
    },
    eliminarDelCarrito(state, producto) {
      const index = state.carrito.findIndex(item => item.id === producto.id);
      if (index !== -1) {
        state.carrito.splice(index, 1);
      }
    }
  }
});

export default store;
